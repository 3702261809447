import axios from "axios";

export default {
  namespaced: true,
  state: {
    discount: null,
  },
  getters: {
    discount(state) {
      return state.discount;
    },
  },
  mutations: {
    SET_DISCOUNT(state, data) {
        state.discount = data;
    },

    NEW_DISCOUNT(state, data) {
        let items = state.discount.concat(data);
        state.discount = items;
    },

    UPDATE_DISCOUNT(state, data) {
        const index = state.discount.findIndex(item => item.id === data.id);
        if(index !== -1){
          state.discount.splice(index, 1, data);
        }
    },

    REMOVE_DISCOUNT(state, id) {
        let discounts = state.discount.filter(veh => veh.id != id);
        state.discount = discounts;
    }
  },
  actions: {
    async fetchDiscount({commit, rootState}) {
        const response = await axios.get(`action/get_discount/${rootState.auth.user.branch_id}`);
        commit('SET_DISCOUNT', response.data);
    },

    async getMainDiscount({commit, rootState}) {
        const response = await axios.get(`action/get_main_discount`);

        return response.data;
    },

    async addDiscount({commit, dispatch, rootState}, data) {
        await axios.post("action/add_discount", {
            discount_type: data.discount_type,
            services_id: data.services_id.value,
            discount_percentage: data.discount_percentage,
            percentage_type: data.percentage_type,
            branch_id: rootState.auth.user.branch_id
        }).then(response => {
          dispatch('notification/addNotification', {
            type: 'success',
            message: 'Successfully Added!'
          }, {root: true});
          
          commit('NEW_DISCOUNT', response.data);
        }, error => {
          console.log(error);
          dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Ops! Something went wrong!'
          }, {root: true});
          
        });
    },

    async updateDiscount({commit, dispatch}, data) {

      await axios.put("action/update_discount", {
          id: data.id,
          discount_type: data.discount_type,
          services_id: data.services_id.value,
          percentage_type: data.percentage_type,
          discount_percentage: data.discount_percentage
      }).then(response => {
        dispatch('notification/addNotification', {
          type: 'success',
          message: 'Successfully Updated!'
        }, {root: true});
        
        commit('UPDATE_DISCOUNT', response.data);
      }, error => {
        console.log(error);
        dispatch('notification/addNotification', {
          type: 'danger',
          message: 'Ops! Something went wrong!'
        }, {root: true});
        
      });
    },

    async removeDiscount({commit, dispatch}, id) {

      await axios.delete(`action/delete_discount/${id}`).then(response => {
        dispatch('notification/addNotification', {
          type: 'success',
          message: 'Successfully Deleted!'
        }, {root: true});
        
        commit('REMOVE_DISCOUNT', response.data);
      }, error => {
        console.log(error);
        dispatch('notification/addNotification', {
          type: 'danger',
          message: 'Ops! Something went wrong!'
        }, {root: true});
        
      });
    },

    async syncDiscount({dispatch}, payload) {
      await axios.post("action/sync_discount", payload).then(response => {
        dispatch('notification/addNotification', {
          type: 'success',
          message: 'Successfully Sync!'
        }, {root: true});
        console.log(response.data);
        dispatch('fetchDiscount');
      }, error => {
        console.log(error);
        dispatch('notification/addNotification', {
          type: 'danger',
          message: 'Ops! Something went wrong!'
        }, {root: true});
      });
    },
  }
};
