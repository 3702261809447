import axios from "axios";

export default {
  namespaced: true,
  state: {
    users: null,
  },
  getters: {
    users(state) {
      return state.users;
    },
  },
  mutations: {
    SET_USERS(state, data) {
        state.users = data;
      },
  
      NEW_USERS(state, data) {
        state.users.unshift(data);
      },
  
      UPDATE_USERS(state, data) {
        state.users.forEach(v => {
          if(v.id == data.id){
            v = data;
          }
        });
      },
  
      REMOVE_USERS(state, id) {
        let users = state.users.filter(user => user.id != id);
        state.users = users;
      },
  },
  actions: {
    async fetchUsers({commit}) {
        const response = await axios.get('action/get_users');

        commit('SET_USERS', response.data);
    },

    async addUser({commit, dispatch}, data) {
        await axios.post("action/add_user", {
            form: data
        }).then(response => {
          dispatch('notification/addNotification', {
            type: 'success',
            message: 'Successfully Added!'
          }, {root: true});
          dispatch('fetchUsers');
        }, error => {
          console.log(error);
          dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Ops! Something went wrong!'
          }, {root: true});
          
        });
    },

    async updateUsers({commit, dispatch},data) {
        await axios.post("action/update_users", {
            form: data
        }).then(response => {
          dispatch('notification/addNotification', {
            type: 'success',
            message: 'Successfully Updated!'
          }, {root: true});

          dispatch('fetchUsers');
        }, error => {
          console.log(error);
          dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Ops! Something went wrong!'
          }, {root: true});
          
        });
        
    },

    async removeUsers({ commit, dispatch }, data) {
        await axios.post("action/delete_users", {
            id: data
        }).then(response => {
          dispatch('notification/addNotification', {
            type: 'success',
            message: 'Successfully Deleted!'
          }, {root: true});

          commit('REMOVE_USERS', response.data);
        }, error => {
          console.log(error);
          dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Ops! Something went wrong!'
          }, {root: true});
          
        });
    },
  }
};
