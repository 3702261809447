import axios from "axios";

export default {
  namespaced: true,
  state: {
    customers: null,
    completed_trans: null
  },
  getters: {
    size(state) {
      return state.customers;
    },
    completed_transaction(state){
      return state.completed_trans;
    },
  },
  mutations: {
    SET_CUSTOMERS(state, data) {
        state.customers = data;
    },
    SET_COMPLETED_TRANSACTION(state, data) {
      state.completed_trans = data;
    },
    UPDATE_COMPLETED_TRANSACTION(state, data) {
      const index = state.completed_trans.findIndex(item => item.id === data.id);
      if(index !== -1){
        state.completed_trans.splice(index, 1, data);
      }
    },
    REMOVE_COMPLETED_TRANSACTION(state, id) {
      let completed = state.completed_trans.filter(veh => veh.id != id);
      state.completed_trans = completed;
    },
  },
  actions: {
    async fetchCompletedTransaction({commit}) {
      const response = await axios.get('action/get_completed_transaction');
      
      commit('SET_COMPLETED_TRANSACTION', response.data);
    },

    async fetchCustomers({commit}, data) {
        const response = await axios.post('action/get_customers', data);

        commit('SET_COMPLETED_TRANSACTION', response.data);
    },

    async submitUpdate({commit, dispatch},data) {
      return new Promise((resolve, reject) => {
        axios.post("action/update_services", {
            form: data
        }).then(response => {

          dispatch('notification/addNotification', {
            type: 'success',
            message: 'Successfully Updated!'
          }, {root: true});
          
          const params = {
            data: response.data,
            loggedID: localStorage.getItem('loggedID')
          }
          resolve(params);
          commit('UPDATE_COMPLETED_TRANSACTION', response.data);
          
        }, () => {
          dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Ops! Something went wrong!'
          }, {root: true});
          
        });
      })
    },

    async removeTransaction({ commit, dispatch}, data) {
      return new Promise((resolve, reject) => {
        axios.delete(`action/delete_transaction/${data}`).then(response => {
          dispatch('notification/addNotification', {
            type: 'success',
            message: 'Successfully Deleted!'
          }, {root: true});
          const params = {
            data: response.data,
            loggedID: localStorage.getItem('loggedID')
          }
          resolve(params);
          commit('REMOVE_COMPLETED_TRANSACTION', response.data.id);
        },() => {

          dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Ops! Something went wrong!'
          }, {root: true});
        });
      })
    },

  }
};
