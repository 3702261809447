import axios from "axios";
export default {
  namespaced: true,
  state: {
    notes: null,
  },
  getters: {
    notes(state) {
      return state.notes;
    },
  },
  mutations: {
    SET_NOTE(state, data) {
        state.notes = data;
    },
  
  },
  actions: {
    async fetchNote({commit}, id) {
        const response = await axios.get(`action/get_note/${id}`);

        commit('SET_NOTE', response.data);
    },

    async findNote({commit}, id) {
        const response = await axios.get(`action/find_note/${id}`);
        
        commit('SET_NOTE', response.data);
    },

    async findNoteDashboard({commit}, id) {
        const response = await axios.get(`action/get_note/${id}`);
        
        commit('SET_NOTE', response.data);
    },
  
    async addNote({commit, dispatch, rootState}, data) {

        if(data.location == 'dashboard'){
            await axios.post("action/add_note", {
                id: rootState.auth.user.id,
                message: data.message,
                date: data.date,
                location: 'dashboard'
              }).then(response => {
                dispatch('notification/addNotification', {
                  type: 'success',
                  message: 'Successfully Added!'
                }, {root: true});
                
                dispatch('findNoteDashboard', response.data);
              }, () => {
                dispatch('notification/addNotification', {
                  type: 'danger',
                  message: 'Ops! Something went wrong!'
                }, {root: true});
                
              });
        } else if(data.location == 'edit'){
            await axios.post("action/add_note", {
                id: rootState.auth.user.id,
                message: data.message,
                member_id: data.member_id,
              }).then(response => {
                dispatch('notification/addNotification', {
                  type: 'success',
                  message: 'Successfully Added!'
                }, {root: true});
        
                dispatch('findNote', response.data);
              }, () => {
                dispatch('notification/addNotification', {
                  type: 'danger',
                  message: 'Ops! Something went wrong!'
                }, {root: true});
                
              });
        } else{
            await axios.post("action/add_note", {
                id: rootState.auth.user.id,
                message: data.message,
                member_id: data.member_id.value,
              }).then(response => {
                dispatch('notification/addNotification', {
                  type: 'success',
                  message: 'Successfully Added!'
                }, {root: true});
        
                dispatch('findNote', response.data);
              }, () => {
                dispatch('notification/addNotification', {
                  type: 'danger',
                  message: 'Ops! Something went wrong!'
                }, {root: true});
                
              });
        }
        
      }, 

      async deleteNote({commit, dispatch}, data) {
        if(data.member_id){
            await axios.post("action/delete_note", {
                member_id: data.member_id,
                id: data.id
            }).then(response => {
              dispatch('notification/addNotification', {
                type: 'success',
                message: 'Successfully Deleted!'
              }, {root: true});
      
              dispatch('findNote', response.data);
            }, () => {
              dispatch('notification/addNotification', {
                type: 'danger',
                message: 'Ops! Something went wrong!'
              }, {root: true});
            });
        }else{
            
            await axios.post("action/delete_note", {
                id: data.id,
                user_id: data.user_id
            }).then(response => {
              dispatch('notification/addNotification', {
                type: 'success',
                message: 'Successfully Deleted!'
              }, {root: true});
              
              dispatch('findNoteDashboard', response.data);
            }, () => {
              dispatch('notification/addNotification', {
                type: 'danger',
                message: 'Ops! Something went wrong!'
              }, {root: true});
            });
        }
        
      },  

  }
};
