import axios from "axios";

export default {
  namespaced: true,
  state: {
    operation_inventory: [],
    notifier_operation_inventory: [],
    notifier_inventory: []
  },
  mutations: {
    SET_OPERATION_INVENTORY(state, data) {
        state.operation_inventory = data;
    },
    SET_NOTIFIER_OPERATION_INVENTORY(state, data) {
      state.notifier_operation_inventory = data;
  },
  },
  actions: {
    async fetchOperationInventory({commit}) {
        const response = await axios.get('action/operation_inventory');
        commit('SET_OPERATION_INVENTORY', response.data);
    },

    async fetchNotifierOperationInventory({commit}) {
        try{
          const response = await axios.get('action/notifier_operation_inventory');
          commit('SET_NOTIFIER_OPERATION_INVENTORY', response.data);
        }catch(e){
          console.log(e);
        }
    },

    async stockOutInventory({commit}, payload) {
        const response = await axios.put(`action/operation_inventory/${payload.id}`, payload);
        try{
          // const response = await axios.put(`action/operation_inventory/${payload}`, payload);
          console.log(response.data);
        }catch (e){
          console.log(e);
        }
        commit('SET_OPERATION_INVENTORY', response.data);
    },

    async updateNotifier({commit, dispatch}, payload) {
        try{
          const response = await axios.put(`action/update_notifier/${payload.id}`, payload);
          dispatch('notification/addNotification', {
            type: 'success',
            message: 'Successfully Updated!'
          }, {root: true});
          dispatch('fetchNotifierOperationInventory');
        }catch (e){
          console.log(e);
        }
        
    },
    
  }
};
