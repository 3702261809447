import {
  cilApplicationsSettings,
  cilDrop,
  cilPuzzle,
  cilMoon,
  cilSpeedometer,
  cilSun,
  cilLockLocked,
  cilUser,
  cilBell,
  cilUserFollow,
  cilUserUnfollow,
  cilChartPie,
  cilBasket,
  cilHome,
  cilStar,
  cilMoney,
  cilWallet,
  cilList,
  cilSpeech,
  cilSettings,
  cilTransfer,
  cilDollar,
  cilGroup,
  cilAddressBook,
  cilBank,
  cilBook,
  cilCircle,
  cilCog,
  cilBuilding,
  cilBarChart,
  cilFactory,
  cilPlus,
  cilCalendar,
  cilLocationPin,
  cibSkype,
  cilPencil,
  cilCarAlt,
  cilTrash,
  cilGrid,
  cilPrint,
  cilEnvelopeClosed,
  cilIndustry,
  cilVector,
  cilCalculator,
  cilExitToApp,
  cilMinus,
  cilCamera,
  cilInfo,
  cilChevronRight,
  cilCreditCard,
  cilSave,
  cilBellExclamation,
  cilMagnifyingGlass,
  cilAvTimer,
  cilFile,
  cilArrowCircleLeft,
  cilSync,
  cilCheckAlt,
  cilX,
  cilWarning,
  cilCheckCircle
} from '@coreui/icons'
import { logo } from './logo'

export const iconsSet = Object.assign({}, {
  cilApplicationsSettings,
  cilDrop,
  cilPuzzle,
  cilMoon,
  cilSpeedometer,
  cilSun,
  logo,
  cilLockLocked,
  cilUser,
  cilBell,
  cilUserFollow,
  cilUserUnfollow,
  cilChartPie,
  cilBasket,
  cilHome,
  cilStar,
  cilMoney,
  cilWallet,
  cilList,
  cilSpeech,
  cilSettings,
  cilTransfer,
  cilDollar,
  cilGroup,
  cilAddressBook,
  cilBank,
  cilCircle,
  cilBook,
  cilCog,
  cilBuilding,
  cilBarChart,
  cilFactory,
  cilPlus,
  cilCalendar,
  cilLocationPin,
  cibSkype,
  cilPencil,
  cilCarAlt,
  cilTrash,
  cilGrid,
  cilPrint,
  cilEnvelopeClosed,
  cilIndustry,
  cilVector,
  cilCalculator,
  cilExitToApp,
  cilMinus,
  cilCamera,
  cilInfo,
  cilChevronRight,
  cilCreditCard,
  cilSave,
  cilBellExclamation,
  cilMagnifyingGlass,
  cilAvTimer,
  cilFile,
  cilArrowCircleLeft,
  cilSync,
  cilCheckAlt,
  cilX,
  cilWarning,
  cilCheckCircle
})
