import axios from "axios";

export default {
  namespaced: true,
  state: {
    stockin: null,
    stockin_temp: [],
    stockin_edit_temp: [],
  },
  getters: {
    stockin(state) {
      return state.stockin;
    },

    stockin_temp(state) {
      return state.stockin_temp;
    },

    stockin_edit_temp(state) {
      return state.stockin_edit_temp;
    },
  },
  mutations: {
    SET_STOCKIN(state, data) {
        state.stockin = data;
    },

    SET_STOCKIN_TEMP(state, data) {
        state.stockin_temp = data;
    },

    SET_STOCKIN_EDIT_TEMP(state, data) {
        state.stockin_edit_temp = data;
    },
    
    NEW_STOCKIN(state, data) {
      let items = data.concat(state.stockin);
      state.stockin = items;
    },

    NEW_STOCKIN_TEMP(state, data) {
      let items = state.stockin_temp.concat(data);
      state.stockin_temp = items;
    },

    NEW_STOCKIN_EDIT_TEMP(state, data) {
      let items = state.stockin_edit_temp.concat(data);
      state.stockin_edit_temp = items;
    },

    REMOVE_STOCKIN_TEMP(state, id) {
      let item = state.stockin_temp.filter(veh => veh.item_id != id);
      state.stockin_temp = item;
    },

    UPDATE_STOCKIN(state, data){
        const index = state.stockin.findIndex(item => item.id === data.id);
        if(index !== -1){
          state.stockin.splice(index, 1, data);
        }
    },

    REMOVE_STOCKIN(state, id) {
      let item = state.stockin.filter(veh => veh.id != id);
      state.stockin = item;
    },
  },
  actions: {
    async fetchStockin({commit, rootState}) {
        const response = await axios.get(`action/get_stockin/${rootState.auth.user.branch_id}`);
        console.log(response.data);
        commit('SET_STOCKIN', response.data);
    },

    async addStockin({commit, dispatch}, data) {
        await axios.post("action/add_stockin", data.formData, data.config).then(response => {
          dispatch('notification/addNotification', {
            type: 'success',
            message: 'Successfully Added!'
          }, {root: true});

          response.data.item.forEach(item => {
            commit('item/UPDATE_ITEM', item, {root: true});
          })
          commit('SET_STOCKIN_TEMP', []);
          commit('NEW_STOCKIN', [response.data.stockin]);
        }, () => {
          dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Ops! Something went wrong!'
          }, {root: true});
        });
    },

    async updateStockin({commit, dispatch}, data) {
      console.log(data);
        await axios.post("action/update_stockin", data.formData, data.config).then(response => {
          dispatch('notification/addNotification', {
            type: 'success',
            message: 'Successfully Added!'
          }, {root: true});

          response.data.item.forEach(item => {
            commit('item/UPDATE_ITEM', item, {root: true});
          })
          
          commit('NEW_STOCKIN', [response.data.stockin]);
        }, () => {
          dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Ops! Something went wrong!'
          }, {root: true});
        });
    },

    async addStockinTemp({commit}, data) {
          commit('NEW_STOCKIN_TEMP', data);
    },

    async fetchStockinEditTemp({commit}, data) {
          commit('SET_STOCKIN_EDIT_TEMP', data);
    },

    async addStockinEditTemp({commit}, data) {
          commit('NEW_STOCKIN_EDIT_TEMP', data);
    },
    
    async removeStockinTemp({commit}, id) {
        commit('REMOVE_STOCKIN_TEMP', id);
    },

    // async updateStockin({commit, dispatch, rootState}, data) {
    //     await axios.put("action/update_item", {
    //         id: data.id,
    //         brand: data.brand,
    //         particular: data.particular,
    //         unit_id: data.unit.value,
    //         category_id: data.category.value,
    //         qty: data.qty,
    //         wg_cost: data.wg_cost,
    //         franchise_cost: data.franchise_cost,
    //         branch_id: rootState.auth.user.branch_id,
    //         user_id: rootState.auth.user.id
    //     }).then(response => {
    //       dispatch('notification/addNotification', {
    //         type: 'success',
    //         message: 'Successfully Updated!'
    //       }, {root: true});
          
    //       commit('UPDATE_ITEM', response.data);
    //     }, () => {
    //       dispatch('notification/addNotification', {
    //         type: 'danger',
    //         message: 'Ops! Something went wrong!'
    //       }, {root: true});
    //     });
    // },

    async removeStockin({commit, dispatch}, data) {
        await axios.delete(`action/delete_stockin/${data}`).then(response => {
          dispatch('notification/addNotification', {
            type: 'success',
            message: 'Successfully Deleted!'
          }, {root: true});
          commit('REMOVE_STOCKIN', response.data.id);

          response.data.item.forEach(item => {
            commit('item/UPDATE_ITEM', item, {root: true});
          });
          
        }, () => {
          dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Ops! Something went wrong!'
          }, {root: true});
        });
    },
  }
};
