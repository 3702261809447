import axios from "axios";

export default {
  namespaced: true,
  state: {
    waiting: null,
    inprogress: null,
    completed: null,
    info: null,
    temp_trans: [],
    temp_edit_trans: [],
    work_order: null,
    new_member_no: null,
    first_trans: false,
    completed_trans: null,
    is_first_trans: false,
    deleted_transaction: []
  },
  getters: {
    waiting(state) {
      return state.waiting;
    },

    completed_transaction(state){
      return state.completed_trans;
    },

    is_first_trans(state){
      return state.is_first_trans;
    },

    new_member_no(state){
      return state.new_member_no;
    },

    work_order(state){
      return state.work_order;
    },

    first_trans(state){
      return state.first_trans;
    },

    inprogress(state) {
        return state.inprogress;
    },

    completed(state){
      return state.completed;
    },

    info(state) {
        return state.info;
    },

    temp_trans(state){
      return state.temp_trans;
    },

    temp_edit_trans(state){
      return state.temp_edit_trans;
    }
  },
  mutations: {
      SET_WAITING(state, data) {
        state.waiting = data;
      },

      SET_DELETED_TRANSACTION(state, data) {
        state.deleted_transaction = data;
      },

      NEW_DELETED_TRANSACTION(state, data) {
        let items = state.deleted_transaction.concat(data);
        state.deleted_transaction = items;
      },

      DELETE_DELETED_TRANSACTION(state, id) {
        let waiting = state.deleted_transaction.filter(veh => veh.id != id);
        state.deleted_transaction = waiting;
      },

      SET_NEW_MEMBER(state, data){
        state.new_member_no = data;
      },

      SET_FIRST_TRANS(state, data){
        state.first_trans = data;
      },

      SET_IF_FIRST_TRANS(state, data){
        state.is_first_trans = data;
      },

      SET_WORKORDER(state, data) {
        state.work_order = data;
      },

      SET_COMPLETED(state, data) {
        state.completed = data;
      },

      SET_COMPLETED_TRANSACTION(state, data) {
        state.completed_trans = data;
      },

      SET_INFO(state, data) {
        state.info = data;
      },

      SET_TEMPTRANS(state, data) {
        state.temp_trans = data;
      },
  
      NEW_WAITING(state, data) {
        let items = state.waiting.concat(data);
        state.waiting = items;
      },

      NEW_TEMPTRANS(state, data) {
        state.temp_trans.unshift(data);
      },
      

      NEW_EDIT_TEMPTRANS(state, data) {
        state.temp_edit_trans.unshift(data);
      },

      SET_EDIT_TEMPTRANS(state, data) {
        state.temp_edit_trans = data;
      },
  
      UPDATE_WAITING(state, data) {
        const index = state.waiting.findIndex(item => item.id === data.id);
        if(index !== -1){
          state.waiting.splice(index, 1, data);
        }
      },
  
      REMOVE_WAITING(state, id) {
        let waiting = state.waiting.filter(veh => veh.id != id);
        state.waiting = waiting;
      },

      REMOVE_TEMPTRANS(state, services_id) {
        let temp_trans = state.temp_trans.filter(veh => veh.services_id != services_id);
        state.temp_trans = temp_trans;
      },

      REMOVE_TEMPEDITTRANS(state, services_id) {
        let temp_edit_trans = state.temp_edit_trans.filter(veh => veh.services_id != services_id);
        state.temp_edit_trans = temp_edit_trans;
      },

      SET_INPROGRESS(state, data) {
        state.inprogress = data;
      },
  
      NEW_INPROGRESS(state, data) {
        state.inprogress.unshift(data);
      },
  
      UPDATE_INPROGRESS(state, data) {
        const index = state.inprogress.findIndex(item => item.id === data.id);
        if(index !== -1){
          state.inprogress.splice(index, 1, data);
        }
      },
  
      REMOVE_INPROGRESS(state, id) {
        let inprogress = state.inprogress.filter(veh => veh.id != id);
        state.services = inprogress;
      },
  },
  actions: {
    async fetchWaiting({commit, rootState}) {
        const response = await axios.get(`action/get_waiting/${rootState.auth.user.branch_id}`);
        commit('SET_WAITING', response.data);
    },

    async deletedTransaction({commit, rootState}) {
        const response = await axios.get(`action/get_deleted_transaction`);
        
        commit('SET_DELETED_TRANSACTION', response.data);
    },

    async fetchWorkOrder({commit}) {
      const response = await axios.get('action/get_transaction_last');
        commit('SET_WORKORDER', response.data);
    },

    async checkFirstTrans({commit}, id) {
      const response = await axios.get(`action/check_first_trans/${id}`);
      
      if(response.data){
        commit('SET_IF_FIRST_TRANS', false);
      }else{
        commit('SET_IF_FIRST_TRANS', true);
      }
      
        // commit('SET_WORKORDER', response.data);
    },

    async checkFirstTransNull({commit}) {
      
        commit('SET_IF_FIRST_TRANS', false);

    },

    
    async fetchCompleted({commit}) {
      const response = await axios.get('action/get_completed');

      commit('SET_COMPLETED', response.data);
    },

    async fetchCompletedTransaction({commit}) {
      const response = await axios.get('action/get_completed_transaction');
      
      commit('SET_COMPLETED_TRANSACTION', response.data);
    },

    async fetchInfo({commit}, id) {
        const response = await axios.post('action/get_info', {
            id: id
        });
       
        commit('SET_INFO', response.data);
    },

    async getNewMemberNo({commit}){
      const response = await axios.get('action/get_new_member_no');
      return response.data;
    },

    async checkTransaction({commit}, data){
      await axios.post('action/check_if_first_trans', data).then(response => {
        var bool = '';
        if(response.data){
          if(response.data.temp_trans != null){
            bool = false;
          }else{
            bool = true;
          }
        }else{
          bool = true;
        }

        commit('SET_FIRST_TRANS', bool);
      });
      
      
    },

    async directCheckTransaction({commit}, data){
      
        commit('SET_FIRST_TRANS', data);
      
    },

    async getNewMemberNoNull({commit}){
      
    commit('SET_NEW_MEMBER', null);
    },

    async tempTransNull({commit}){
      
      commit('SET_TEMPTRANS', []);
      },

    async fetchInprogress({commit}) {
        const response = await axios.get('action/get_inprogress');

        commit('SET_INPROGRESS', response.data);
    },

    async addTransaction({commit, dispatch}, data) {
      return new Promise((resolve, reject) => {
        axios.post("action/add_transaction", {
          form: data.transaction,
          payment: data.payment,
          temp_trans: data.temp_trans
        }).then(response => {
            dispatch('notification/addNotification', {
              type: 'success',
              message: 'Successfully Added!'
            }, {root: true});

            dispatch('activity_log/addActivityLog', {
              activity: 'has added transaction with W.O #:' + response.data.work_order
            }, {root: true});

          const params = {
            data: response.data,
            loggedID: localStorage.getItem('loggedID')
          }
          resolve(params);
          commit('NEW_WAITING', response.data);
          commit('SET_NEW_MEMBER', null);
          commit('SET_TEMPTRANS', []);
        }, error => {
          reject(error);
            dispatch('notification/addNotification', {
              type: 'danger',
              message: 'Ops! Something went wrong!'
            }, {root: true});
          
        });
      })
    },

    async startWashSubmit({dispatch, commit}, data) {
      return new Promise((resolve, reject) => {
        axios.post("action/start_wash", {
          form: data
        }).then(response => {
          dispatch('notification/addNotification', {
            type: 'success',
            message: 'Successfully!'
          }, {root: true});

          const params = {
            data: response.data,
            loggedID: localStorage.getItem('loggedID')
          }
          resolve(params);

          commit('UPDATE_WAITING', response.data);
        }, error => {
          reject(error);
          dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Ops! Something went wrong!'
          }, {root: true});
          
        });
      })
    },

    async editWashboy({dispatch, commit}, data) {
      return new Promise((resolve, reject) => {
        axios.post("action/edit_washboy", {
          form: data
        }).then(response => {
          dispatch('notification/addNotification', {
            type: 'success',
            message: 'Successfully!'
          }, {root: true});

          const params = {
            data: response.data,
            loggedID: localStorage.getItem('loggedID')
          }
          resolve(params);

          commit('UPDATE_WAITING', response.data);
        }, error => {
          reject(error);
          dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Ops! Something went wrong!'
          }, {root: true});
          
        });
      })
    },

    async submitPayment({dispatch, commit}, data) {
      return new Promise((resolve, reject) => {
        axios.post("action/submit_payment", {
          form: data.data,
          id: data.id
        }).then(response => {
          dispatch('notification/addNotification', {
            type: 'success',
            message: 'Successfully Submitted!'
          }, {root: true});

          const params = {
            data: response.data,
            loggedID: localStorage.getItem('loggedID')
          }
          resolve(params);

          commit('UPDATE_WAITING', response.data);
        }, error => {
          reject(error);
          dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Ops! Something went wrong!'
          }, {root: true});
          
        });
      })
    },

    async submitCompleted({commit, dispatch}, data) {
      return new Promise((resolve, reject) => {
        axios.post("action/submit_completed", {
          id: data.id,
          property_id: data.property_id
        }).then(response => {

          dispatch('notification/addNotification', {
            type: 'success',
            message: 'Successfully Submitted!'
          }, {root: true});

          const params = {
            data: response.data,
            loggedID: localStorage.getItem('loggedID')
          }
          resolve(params);
          commit('REMOVE_WAITING', response.data);
        }, error => {
          reject(error);
          dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Ops! Something went wrong!'
          }, {root: true});
          
        });
      });
    },

    async addServices({commit}, data) {

      commit('NEW_TEMPTRANS', data);
    },

    async fetchTempEditTrans({commit}, data) {

      commit('SET_EDIT_TEMPTRANS', data);
    },

    async addEditServices({commit}, data) {

      commit('NEW_EDIT_TEMPTRANS', data);
    },

    async removeEditServices({ commit }, data) {
      commit('REMOVE_TEMPEDITTRANS', data);
    },

    async removeServices({ commit }, data) {
      commit('REMOVE_TEMPTRANS', data);
    },

    async updateServices({commit,dispatch},data) {
      await axios.post("action/update_vehicle", {
          form: data
      }).then(response => {
        dispatch('notification/addNotification', {
          type: 'success',
          message: 'Successfully Updated!'
        }, {root: true});

        commit('UPDATE_VEHICLE', response.data.form);
      }, () => {
        dispatch('notification/addNotification', {
          type: 'danger',
          message: 'Ops! Something went wrong!'
        }, {root: true});
        
      });
        
    },

    async submitUpdateServices({commit, dispatch},data) {
      return new Promise((resolve, reject) => {
        axios.post("action/update_services", {
            form: data
        }).then(response => {

          dispatch('notification/addNotification', {
            type: 'success',
            message: 'Successfully Updated!'
          }, {root: true});
          
          const params = {
            data: response.data,
            loggedID: localStorage.getItem('loggedID')
          }
          resolve(params);
          commit('UPDATE_WAITING', response.data);
          
        }, () => {
          dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Ops! Something went wrong!'
          }, {root: true});
          
        });
      })
    },
    
    async removeTransaction({ commit, dispatch}, data) {
      return new Promise((resolve, reject) => {
        axios.delete(`action/delete_transaction/${data}`).then(response => {
          dispatch('notification/addNotification', {
            type: 'success',
            message: 'Successfully Deleted!'
          }, {root: true});
          const params = {
            data: response.data,
            loggedID: localStorage.getItem('loggedID')
          }
          resolve(params);
          commit('REMOVE_WAITING', response.data.id);
          commit('NEW_DELETED_TRANSACTION', response.data);

          dispatch('activity_log/addActivityLog', {
            activity: 'deleted transaction with W.O #:' + response.data.work_order
          }, {root: true});
          
        },() => {

          dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Ops! Something went wrong!'
          }, {root: true});
        });
      })
    },

    async removeTransactionForReg({ commit, dispatch}, data){
      return new Promise((resolve, reject) => {
        axios.delete(`action/delete_transaction_for_reg/${data.id}/${data.member_id}`).then(response => {
          dispatch('notification/addNotification', {
            type: 'success',
            message: 'Successfully Deleted!'
          }, {root: true});
          const params = {
            data: response.data,
            loggedID: localStorage.getItem('loggedID')
          }
          resolve(params);
          commit('REMOVE_WAITING', response.data.id);
          commit('NEW_DELETED_TRANSACTION', response.data);

          dispatch('activity_log/addActivityLog', {
            activity: 'deleted transaction with W.O #:' + response.data.work_order
          }, {root: true});
          
        },() => {

          dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Ops! Something went wrong!'
          }, {root: true});
        });
      })
    },

    async restoreTransaction({ commit, dispatch}, id) {
        const response = await axios.get(`action/restore_transaction/${id}`);
        commit('DELETE_DELETED_TRANSACTION', response.data.id);
        commit('NEW_WAITING', response.data);
    },
    
  }
};
