import axios from "axios";

export default {
  namespaced: true,
  state: {
    personel_category: null,
  },
  getters: {
    personel(state) {
      return state.personel_category;
    },
  },
  mutations: {
    SET_PERSONEL(state, data) {
        state.personel_category = data;
    },
  
  },
  actions: {
    async fetchPersonel({commit}) {
        const response = await axios.get('action/get_personelcategory');

        commit('SET_PERSONEL', response.data);
    },
  }
};
