import axios from "axios";

export default {
  namespaced: true,
  state: {
    vehicle: [],
    vehicle_property: null
  },
  getters: {
    vehicles(state) {
      return state.vehicle;
    },
    vehicle_property(state) {
      return state.vehicle_property;
    },
  },
  mutations: {
      SET_VEHICLE(state, data) {
        state.vehicle = data;
      },

      SET_VEHICLE_PROPERTY(state, data) {
        state.vehicle_property = data;
      },
  
      NEW_VEHICLE(state, data) {
        let items = state.vehicle.concat(data);
        state.vehicle = items;
      },
  
      UPDATE_VEHICLE(state, data) {
        state.vehicle.forEach(v => {
          if(v.id == data.id){
            v = data;
          }
        });
      },
  
      REMOVE_VEHICLE(state, id) {
        let vehicle = state.vehicle.filter(veh => veh.id != id);
        state.vehicle = vehicle;
      },
  },
  actions: {
    async fetchVehicle({commit}) {
        const response = await axios.get('action/get_vehicle');

        commit('SET_VEHICLE', response.data);
    },

    async getMainVehicle({commit}) {
        const response = await axios.get('action/get_main_vehicle');

        return response.data;
    },

    async findProperty({commit}, id) {
      const response = await axios.post('action/get_property', {
          id: id
      });
      
      commit('SET_VEHICLE_PROPERTY', response.data);
    },

    async addVehicle({ commit, dispatch}, data) {
      await axios.post("action/add_vehicle", {
        form: data
      }).then(response => {
        dispatch('notification/addNotification', {
          type: 'success',
          message: 'Successfully Added!'
        }, {root: true});

        commit('NEW_VEHICLE', response.data);
      }, () => {
        dispatch('notification/addNotification', {
          type: 'danger',
          message: 'Ops! Something went wrong!'
        }, {root: true});
        
      });
    },

    async updateVehicle({commit,dispatch},data) {
      await axios.post("action/update_vehicle", {
          form: data
      }).then(response => {
        dispatch('notification/addNotification', {
          type: 'success',
          message: 'Successfully Updated!'
        }, {root: true});
        
        dispatch('fetchVehicle');
        commit('UPDATE_VEHICLE', response.data.form);
      }, () => {
        dispatch('notification/addNotification', {
          type: 'danger',
          message: 'Ops! Something went wrong!'
        }, {root: true});
        
      });
        
    },

    async removeVehicle({ commit, dispatch }, data) {
      await axios.post("action/delete_vehicle", {
          id: data
      }).then(response => {
        dispatch('notification/addNotification', {
          type: 'success',
          message: 'Successfully Deleted!'
        }, {root: true});

        commit('REMOVE_VEHICLE', response.data);
      }, () => {
        dispatch('notification/addNotification', {
          type: 'danger',
          message: 'Ops! Something went wrong!'
        }, {root: true});
      });
    },

    async syncVehicle({commit,dispatch}, payload) {
      await axios.post("action/sync_vehicle", payload).then(response => {
        dispatch('notification/addNotification', {
          type: 'success',
          message: 'Successfully Sync!'
        }, {root: true});
        console.log(response.data);
        // dispatch('fetchVehicle');
      }, () => {
        dispatch('notification/addNotification', {
          type: 'danger',
          message: 'Ops! Something went wrong!'
        }, {root: true});
        
      });
        
    },

  }
};
