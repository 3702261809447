import axios from "axios";

export default {
  namespaced: true,
  state: {
    delivery_receipt: []
  },
  mutations: {
    SET_DELIVERY_RECEIPT(state, data) {
        state.delivery_receipt = data;
    },

    NEW_DELIVERY_RECEIPT(state, data) {
        let delivery_receipt = state.delivery_receipt.concat(data);
        state.delivery_receipt = delivery_receipt;
    },

    UPDATE_DELIVERY_RECEIPT(state, payload){
        let operationToUpdate = state.delivery_receipt.find(item => item.id === payload.id)
        operationToUpdate = payload;
    },

    REMOVE_DELIVERY_RECEIPT(state, id){
        state.delivery_receipt = state.delivery_receipt.filter(item => item.id !== id)
    }
  },
  actions: {
    async fetchDeliveryReceipt({commit}) {
        const response = await axios.get('action/delivery_receipt');
        console.log(response.data);
        commit('SET_DELIVERY_RECEIPT', response.data);
    },

    async addOperationPurchaseOrder({commit, dispatch}, payload) {
      await axios.post("action/operation_purchase_order", payload).then(response => {
        dispatch('notification/addNotification', {
          type: 'success',
          message: 'Successfully Added!'
        }, {root: true});
        
        commit('NEW_OPERATION_PURCHASE_ORDER', response.data);
      }, () => {
        dispatch('notification/addNotification', {
          type: 'danger',
          message: 'Ops! Something went wrong!'
        }, {root: true});
      });
    },

    async updateOperationPurchaseOrder({commit, dispatch}, payload) {
        await axios.put(`action/operation_purchase_order/${payload.id}`, payload).then(response => {
            dispatch('notification/addNotification', {
            type: 'success',
            message: 'Successfully Updated!'
            }, {root: true});
            
            dispatch('fetchOperationPurchaseOrder');
        }, () => {
            dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Ops! Something went wrong!'
            }, {root: true});
        });
    },

    async updateDeliveryReceiptStatus({commit, dispatch}, payload) {
        await axios.put(`action/update_delivery_receipt_status/${payload.id}`, payload).then(response => {
            dispatch('notification/addNotification', {
            type: 'success',
            message: 'Successfully Updated!'
            }, {root: true});
            
            dispatch('fetchDeliveryReceipt');
        }, () => {
            dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Ops! Something went wrong!'
            }, {root: true});
        });
    },

    async OperationDeliveryReceiptCompleted({commit, dispatch}, payload) {
        
        await axios.put(`action/operation_delivery_receipt_completed/${payload.id}`, payload).then(response => {
            dispatch('notification/addNotification', {
            type: 'success',
            message: 'Successfully Updated!'
            }, {root: true});
            console.log(response.data);
            dispatch('fetchOperationDeliveryReceipt');
        }, () => {
            dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Ops! Something went wrong!'
            }, {root: true});
        });
    },

    async updateConvertStatusOperationPurchaseOrder({commit, dispatch}, payload) {
        await axios.put(`action/operation_purchase_order_convert_status/${payload.id}`, payload).then(response => {
            dispatch('notification/addNotification', {
            type: 'success',
            message: 'Successfully Updated!'
            }, {root: true});
            
            dispatch('fetchOperationPurchaseOrder');
        }, () => {
            dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Ops! Something went wrong!'
            }, {root: true});
        });
    },

    async removeOperationDeliveryReceipt({commit, dispatch}, id){
        await axios.delete(`action/operation_delivery_receipt/${id}`).then(response => {
            dispatch('notification/addNotification', {
                type: 'success',
                message: 'Successfully Deleted!'
            }, {root: true});
            commit('REMOVE_OPERATION_DELIVERY_RECEIPT', parseInt(response.data));
            }, () => {
            dispatch('notification/addNotification', {
                type: 'danger',
                message: 'Ops! Something went wrong!'
            }, {root: true});
        });
    },

    async getOperationPoNumber({commit}, id) {
      const response = await axios.get(`action/po_no`);

      return response.data;
    },
    
  }
};
