import axios from "axios";

export default {
  namespaced: true,
  state: {
    target: null,
  },
  getters: {
    target(state) {
      return state.target;
    },
  },
  mutations: {
    SET_TARGET(state, data) {
        state.target = data;
      },
  },
  actions: {
    async fetchTarget({commit}, id) {
        const response = await axios.get(`action/get_target/${id}`);

        commit('SET_TARGET', response.data);
    },

    async updateTarget({dispatch}, data) {
        await axios.post('action/update_target', {
            id: data.id,
            form: data.form
        }).then(response => {
          dispatch('notification/addNotification', {
            type: 'success',
            message: 'Successfully Updated!'
          }, {root: true});

          dispatch('activity_log/addActivityLog', {
            activity: 'has updated target'
          }, {root: true});

          dispatch('fetchTarget', response.data);
        }, error => {
          console.log(error);
          dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Ops! Something went wrong!'
          }, {root: true});
          
        });

        
    },
  }
};
