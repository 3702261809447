import axios from "axios";

export default {
  namespaced: true,
  state: {
    washboy: null,
    attendance: null,
    attendance_report: null,
    searched_washboy: null,
    wash_history: null
  },
  getters: {
    washboy(state) {
      return state.washboy;
    },
    attendance(state) {
      return state.attendance;
    },
    attendance_report(state) {
      return state.attendance_report;
    },
    searched_washboy(state) {
      return state.searched_washboy;
    },
    wash_history(state) {
      return state.wash_history;
    },
  },
  mutations: {
    SET_WASHBOY(state, data) {
        state.washboy = data;
    },

    SET_ATTENDANCE(state, data) {
        state.attendance = data;
    },

    SET_SEARCHED_WASHBOY(state, data) {
        state.searched_washboy = data;
    },

    SET_WASH_HISTORY(state, data) {
        state.wash_history = data;
    },

    SET_ATTENDANCE_REPORT(state, data) {
        state.attendance_report = data;
    },

    UPDATE_WASHBOY(state, data) {
      state.washboy.forEach(v => {
        if(v.id == data.id){
          v = data;
        }
      });
    },
    
    REMOVE_WASHBOY(state, id) {
      let washboy = state.washboy.filter(veh => veh.id != id);
      state.washboy = washboy;
    },
  },
  actions: {
    async fetchWashboy({commit, rootState}) {
        const response = await axios.get(`action/get_washboy/${rootState.auth.user.branch_id}`);

        commit('SET_WASHBOY', response.data);
    },

    async fetchAttendance({commit, rootState}) {
        await axios.get(`action/get_attendance/${rootState.auth.user.branch_id}`).then(response => {
          commit('SET_ATTENDANCE', response.data);
        });
    },

    async searchWashboy({commit, rootState}, id) {
        await axios.get(`action/search_washboy/${rootState.auth.user.branch_id}/${id}`).then(response => {
          
          commit('SET_SEARCHED_WASHBOY', response.data);
        });
    },

    async washHistory({commit, rootState}, id) {
        await axios.get(`action/wash_history/${rootState.auth.user.branch_id}/${id}`).then(response => {
          console.log(response.data);
          commit('SET_WASH_HISTORY', response.data);
        });
    },

    async findAttendance({commit}, data) {
        await axios.get(`action/find_attendance/${data.id}/date?start=${data.start}&end=${data.end}`).then(response => {
          console.log(response.data);
          commit('SET_ATTENDANCE_REPORT', response.data);
        });
    },

    async saveAttendance({commit, dispatch}, data) {
      if(data.time_type == "timein"){
        if(data.time >= data.valid_checkin_from && data.time <= data.valid_checkin_to){
          await axios.post('action/save_attendance', data).then(response => {
            dispatch('notification/addNotification', {
              type: 'success',
              message: 'Successfully Set!'
            }, {root: true});
            commit('SET_ATTENDANCE', response.data);
          }, () => {
            
            dispatch('notification/addNotification', {
              type: 'danger',
              message: 'Ops! Something went wrong!'
            }, {root: true});
          });
        }else{
          dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Invalid Timein!'
          }, {root: true});
        }
      }else{
        if(data.time >= data.valid_checkout_from && data.time <= data.valid_checkout_to){
          await axios.post('action/save_attendance', data).then(response => {
            dispatch('notification/addNotification', {
              type: 'success',
              message: 'Successfully Set!'
            }, {root: true});
            commit('SET_ATTENDANCE', response.data);
          }, () => {
            
            dispatch('notification/addNotification', {
              type: 'danger',
              message: 'Ops! Something went wrong!'
            }, {root: true});
          });
        }else{
          dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Invalid Timeout!'
          }, {root: true});
        }
      }
      
    },

    async addWashboy({dispatch, rootState}, data) {

      await axios.post("action/add_washboy", {
        form: data,
        branch_id: rootState.auth.user.branch_id
      }).then(() => {
        dispatch('notification/addNotification', {
          type: 'success',
          message: 'Successfully Added!'
        }, {root: true});

        dispatch('fetchWashboy');
      }, () => {
        dispatch('notification/addNotification', {
          type: 'danger',
          message: 'Ops! Something went wrong!'
        }, {root: true});
        
      });
    },

    async updateWashboy({dispatch, rootState}, data) {

      await axios.post("action/update_washboy", {
        form: data,
        branch_id: rootState.auth.user.branch_id
      }).then(() => {
        dispatch('notification/addNotification', {
          type: 'success',
          message: 'Successfully Added!'
        }, {root: true});

        dispatch('fetchWashboy');
      }, () => {
        dispatch('notification/addNotification', {
          type: 'danger',
          message: 'Ops! Something went wrong!'
        }, {root: true});
        
      });
    },

    async removeWashboy({ commit, dispatch }, data) {
      await axios.post("action/delete_washboy", {
          id: data.id
      }).then(response => {
        dispatch('notification/addNotification', {
          type: 'success',
          message: 'Successfully Deleted!'
        }, {root: true});

        commit('REMOVE_WASHBOY', response.data);
      }, error => {
        console.log(error);
        dispatch('notification/addNotification', {
          type: 'danger',
          message: 'Ops! Something went wrong!'
        }, {root: true});
      });
    },
  }
};
