import axios from "axios";

export default {
  namespaced: true,
  state: {
    purchase_request: null
  },
  mutations: {
    SET_PURCHASE_REQUEST(state, data) {
        state.purchase_request = data;
    },

    NEW_PURCHASE_REQUEST(state, data) {
        let purchase_request = state.purchase_request.concat(data);
        state.purchase_request = purchase_request;
    },
  },
  actions: {
    async fetchPurchaseRequest({commit}) {
        const response = await axios.get('action/purchase_request');
        console.log(response);
        commit('SET_PURCHASE_REQUEST', response.data);
    },

    async findSize({commit}, id) {
      const response = await axios.get(`action/get_size_id/${id}`);

      commit('SET_SIZE_ID', response.data);
    },

    async addPurchaseRequest({commit, dispatch}, payload) {
      await axios.post("action/purchase_request", payload).then(response => {
        dispatch('notification/addNotification', {
          type: 'success',
          message: 'Successfully Added!'
        }, {root: true});
        
        // commit('NEW_UNIT', response.data);
      }, () => {
        dispatch('notification/addNotification', {
          type: 'danger',
          message: 'Ops! Something went wrong!'
        }, {root: true});
      });
    },

    async getPrNumber({commit}, id) {
      const response = await axios.get(`action/pr_no`);

      return response.data;
    },
    
  }
};
