import axios from "axios";

export default {
  namespaced: true,
  state: {
    unit: null,
  },
  getters: {
    unit(state) {
      return state.unit;
    },
  },
  mutations: {
    SET_UNIT(state, data) {
        state.unit = data;
    },

    NEW_UNIT(state, data) {
      let unit = state.unit.concat(data);
      state.unit = unit;
    },


    REMOVE_UNIT(state, id) {
      let unit = state.unit.filter(veh => veh.id != id);
      state.unit = unit;
    },
  },
  actions: {
    async fetchUnit({commit, rootState}) {
        const response = await axios.get(`action/get_unit/${rootState.auth.user.branch_id}`);

        commit('SET_UNIT', response.data);
    },

    async addUnit({commit, dispatch, rootState}, data) {
        await axios.post("action/add_unit", {
            unit_name: data.unit_name,
            branch_id: rootState.auth.user.branch_id
        }).then(response => {
          dispatch('notification/addNotification', {
            type: 'success',
            message: 'Successfully Added!'
          }, {root: true});
          
          commit('NEW_UNIT', response.data);
        }, () => {
          dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Ops! Something went wrong!'
          }, {root: true});
        });
    },

    async deleteUnit({commit, dispatch}, data) {
        await axios.delete(`action/delete_unit/${data.unit_id.value}`).then(response => {
          dispatch('notification/addNotification', {
            type: 'success',
            message: 'Successfully Deleted!'
          }, {root: true});
          
          commit('REMOVE_UNIT', response.data);
        }, () => {
          dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Ops! Something went wrong!'
          }, {root: true});
        });
    },
  }
};
