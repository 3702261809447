import axios from "axios";

export default {
  namespaced: true,
  state: {
    payments: null,
  },
  mutations: {
    SET_PAYMENT(state, data) {
        state.payments = data;
    },

    REMOVE_UNIT(state, id) {
      let payments = state.payments.filter(veh => veh.id != id);
      state.payments = payments;
    },
  },
  actions: {
    async fetchPayment({commit, rootState}) {
        const response = await axios.get(`action/get_unit/${rootState.auth.user.branch_id}`);

        commit('SET_UNIT', response.data);
    },

    async addPayment({commit, dispatch}, payload) {
        let response = await axios.post("action/payments", payload);

        dispatch('notification/addNotification', {
        type: 'success',
        message: 'Successfully Added!'
        }, {root: true});

        dispatch('delivery_receipt/fetchDeliveryReceipt', [], {root: true});
        
        return response.data;
    },

    async updatePayment({commit, dispatch}, payload) {
        let response = await axios.put(`action/payments/${payload.id}`, payload);

        dispatch('notification/addNotification', {
        type: 'success',
        message: 'Successfully Updated!'
        }, {root: true});
        
        return response.data;
    },

    async deletePayment({commit, dispatch}, id) {
        let response = await axios.delete(`action/payments/${id}`);

        dispatch('notification/addNotification', {
        type: 'success',
        message: 'Successfully Deleted!'
        }, {root: true});
        
        return response.data;
    },

    async deleteUnit({commit, dispatch}, data) {
        await axios.delete(`action/delete_unit/${data.unit_id.value}`).then(response => {
          dispatch('notification/addNotification', {
            type: 'success',
            message: 'Successfully Deleted!'
          }, {root: true});
          
          commit('REMOVE_UNIT', response.data);
        }, () => {
          dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Ops! Something went wrong!'
          }, {root: true});
        });
    },
  }
};
