import axios from "axios";

export default {
  namespaced: true,
  state: {
    item_category: null,
  },
  getters: {
    item_category(state) {
      return state.item_category;
    },
  },
  mutations: {
    SET_ITEM_CATEGORY(state, data) {
        state.item_category = data;
    },
    
    NEW_ITEM_CATEGORY(state, data) {
      let items = state.item_category.concat(data);
      state.item_category = items;
    },

    REMOVE_ITEM_CATEGORY(state, id) {
      let item_category = state.item_category.filter(veh => veh.id != id);
      state.item_category = item_category;
    },
  },
  actions: {
    async fetchItemCategory({commit, rootState}) {
        const response = await axios.get(`action/get_item_category/${rootState.auth.user.branch_id}`);

        commit('SET_ITEM_CATEGORY', response.data);
    },

    async addCategory({commit, dispatch, rootState}, data) {
        await axios.post("action/add_category", {
            category_name: data.category_name,
            branch_id: rootState.auth.user.branch_id
        }).then(response => {
          dispatch('notification/addNotification', {
            type: 'success',
            message: 'Successfully Added!'
          }, {root: true});
          
          commit('NEW_ITEM_CATEGORY', response.data);
        }, () => {
          dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Ops! Something went wrong!'
          }, {root: true});
        });
    },

    async deleteCategory({commit, dispatch}, data) {
        await axios.delete(`action/delete_category/${data.category_id.value}`).then(response => {
          dispatch('notification/addNotification', {
            type: 'success',
            message: 'Successfully Deleted!'
          }, {root: true});
          
          commit('REMOVE_ITEM_CATEGORY', response.data);
        }, () => {
          dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Ops! Something went wrong!'
          }, {root: true});
        });
    },
  }
};
