import axios from "axios";

export default {
  namespaced: true,
  state: {
    report: null,
  },
  getters: {
    report(state) {
      return state.report;
    },
  },
  mutations: {
    SET_REPORT(state, data) {
        state.report = data;
    },
  },
  actions: {
    async findReport({commit}, data) {
        const response = await axios.post('action/find_report', data);

        commit('SET_REPORT', response.data);
    },
  }
};
