import axios from "axios";

export default {
  namespaced: true,
  state: {
    size: null,
    size_id: null
  },
  getters: {
    size(state) {
      return state.size;
    },

    size_id(state) {
      return state.size_id;
    },
  },
  mutations: {
    SET_SIZE(state, data) {
        state.size = data;
      },
      
    SET_SIZE_ID(state, data) {
        state.size_id = data;
      },
  },
  actions: {
    async fetchSize({commit}) {
        const response = await axios.get('action/get_size');

        commit('SET_SIZE', response.data);
    },

    async findSize({commit}, id) {
      const response = await axios.get(`action/get_size_id/${id}`);

      commit('SET_SIZE_ID', response.data);
  },
  }
};
