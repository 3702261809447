import axios from "axios";

export default {
  namespaced: true,
  state: {
    owners: null,
    branches: null
  },
  getters: {
    branches(state) {
      return state.branches;
    },
    owners(state) {
      return state.owners;
    },
  },
  mutations: {
      SET_BRANCH(state, data) {
        state.branches = data;
      },

      SET_OWNERS(state, data) {
        state.owners = data;
      },
  
      NEW_BRANCH(state, data) {
        let items = state.branches.concat(data);
        state.branches = items;
      },
  
      UPDATE_BRANCH(state, data) {
        state.branches.forEach(v => {
          if(v.id == data.id){
            v = data;
          }
        });
      },
  
      REMOVE_BRANCH(state, id) {
        let users = state.branches.filter(user => user.id != id);
        state.branches = users;
      },
  },
  actions: {
    async fetchBranch({commit}) {
        const response = await axios.get('action/get_branch');

        commit('SET_BRANCH', response.data);
    },

    async fetchOwners({commit}) {
      const response = await axios.get('action/get_owners');

      commit('SET_OWNERS', response.data);
    },

    async addBranch({commit,dispatch}, data) {
      await axios.post("action/add_branch", {
          form: data
      }).then(response => {
        dispatch('notification/addNotification', {
          type: 'success',
          message: 'Successfully Added!'
        }, {root: true});

        commit('NEW_BRANCH', response.data);
      }, error => {
        console.log(error);
        dispatch('notification/addNotification', {
          type: 'danger',
          message: 'Ops! Something went wrong!'
        }, {root: true});
        
      });
    },

    async updateBranch({commit, dispatch},data) {
      await axios.post("action/update_branch", {
        form: data
      }).then(response => {
        dispatch('notification/addNotification', {
          type: 'success',
          message: 'Successfully Updated!'
        }, {root: true});

        commit('UPDATE_BRANCH', response.data);
      }, error => {
        console.log(error);
        dispatch('notification/addNotification', {
          type: 'danger',
          message: 'Ops! Something went wrong!'
        }, {root: true});
        
      });
        
    },

    async removeBranch({ commit,dispatch }, data) {
      await axios.post("action/delete_branch", {
          id: data
      }).then(response => {
        dispatch('notification/addNotification', {
          type: 'success',
          message: 'Successfully Deleted!'
        }, {root: true});

        commit('REMOVE_BRANCH', response.data);
      }, error => {
        console.log(error);
        dispatch('notification/addNotification', {
          type: 'danger',
          message: 'Ops! Something went wrong!'
        }, {root: true});
        
      });
    },

  }
};
