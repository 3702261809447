import axios from "axios";

export default {
  namespaced: true,
  actions: {
    async sendMail({dispatch}, data) {
        await axios.post("action/send_email", data).then(() => {
            dispatch('notification/addNotification', {
              type: 'success',
              message: 'Successfully Send!'
            }, {root: true});
    
          }, () => {
            dispatch('notification/addNotification', {
              type: 'danger',
              message: 'Ops! Something went wrong!'
            }, {root: true});
            
          });
    },

  }
};
