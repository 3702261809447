import Vue from 'vue'
import Vuex from 'vuex'

import auth from "./auth";
import option from "./option";
import users from "./modules/users";
import branch from "./modules/branch";
import notification from "./modules/notification";
import discount from "./modules/discount";
import owner from "./modules/owners";
import member from "./modules/members";
import size from "./modules/size";
import vehicle from "./modules/vehicle";
import services from "./modules/services";
import sales from "./modules/sales";
import category from "./modules/category";
import washboy from "./modules/washboy";
import dashboard from "./modules/dashboard";
import permission from "./modules/permission";
import note from "./modules/note";
import target from "./modules/target";
import personel from "./modules/personel_category";
import customer from "./modules/customers";
import mail from "./modules/sendmail";
import profile from "./modules/profile";
import report from "./modules/report";
import loading from "./modules/loading";
import incentives from "./modules/incentives";
import recent_page from "./modules/recent_page";
import unit from "./modules/unit";
import expenses from "./modules/expenses";
import shift from "./modules/shift";
import salary from "./modules/salary";
import item_category from "./modules/item_category";
import item from "./modules/item";
import stockin from "./modules/stockin";
import stockout from "./modules/stockout";
import inventory from "./modules/inventory";
import purchase_request from "./modules/purchase_request";
import purchase_order from "./modules/purchase_order";
import operation_purchase_order from "./modules/operation_purchase_order";
import operation_delivery_receipt from "./modules/operation_delivery_receipt";
import operation_inventory from "./modules/operation_inventory";
import delivery_receipt from "./modules/delivery_receipt";
import payment from "./modules/payment";
import company from "./modules/company";
import activity_log from "./modules/activity_log";

Vue.use(Vuex)

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  darkMode: false,
  asideShow: false
}

const mutations = {
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set (state, [variable, value]) {
    state[variable] = value
  },
  toggle (state, variable) {
    state[variable] = !state[variable]
  }
}

export default new Vuex.Store({
  state,
  mutations,
  actions: {},
  modules: {
    auth,
    option,
    users,
    branch,
    notification,
    owner,
    member,
    size,
    vehicle,
    services,
    sales,
    category,
    washboy,
    dashboard,
    permission,
    note,
    target,
    personel,
    customer,
    mail,
    profile,
    report,
    loading,
    incentives,
    recent_page,
    unit,
    expenses,
    shift,
    salary,
    discount,
    item_category,
    item,
    stockin,
    stockout,
    inventory,
    purchase_request,
    purchase_order,
    operation_purchase_order,
    operation_delivery_receipt,
    operation_inventory,
    delivery_receipt,
    payment,
    company,
    activity_log
  }
});
