import axios from "axios";

export default {
  namespaced: true,
  state: {
    expenses: null,
  },
  getters: {
    expenses(state) {
      return state.expenses;
    },
  },
  mutations: {
    SET_EXPENSES(state, data) {
        state.expenses = data;
    },
  },
  actions: {
    async fetchExpenses({commit, rootState}) {
        const response = await axios.get(`action/get_expenses/${rootState.auth.user.branch_id}`);

        commit('SET_EXPENSES', response.data);
    },

    async addExpenses({dispatch, rootState}, data) {
        await axios.post("action/add_expenses", {
            item: data.item,
            qty: data.qty,
            unit: data.unit,
            cost: data.cost,
            date: new Date(data.date).toLocaleDateString(),
            branch_id: rootState.auth.user.branch_id
        }).then(() => {
          dispatch('notification/addNotification', {
            type: 'success',
            message: 'Successfully Added!'
          }, {root: true});
          dispatch('fetchExpenses');
        }, () => {
          dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Ops! Something went wrong!'
          }, {root: true});
        });
    },

    async updateExpenses({dispatch}, data) {
        await axios.post("action/update_expenses", {
            id: data.id,
            item: data.item,
            qty: data.qty,
            unit: data.unit,
            cost: data.cost,
            date: new Date(data.date).toLocaleDateString()
        }).then(() => {
          dispatch('notification/addNotification', {
            type: 'success',
            message: 'Successfully Updated!'
          }, {root: true});
          dispatch('fetchExpenses');
        }, () => {
          dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Ops! Something went wrong!'
          }, {root: true});
        });
    },

    async removeExpenses({dispatch}, data) {
        await axios.delete(`action/delete_expenses/${data}`).then(() => {
          dispatch('notification/addNotification', {
            type: 'success',
            message: 'Successfully Deleted!'
          }, {root: true});
          dispatch('fetchExpenses');
        }, () => {
          dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Ops! Something went wrong!'
          }, {root: true});
        });
    },
  }
};
