import axios from "axios";

export default {
  namespaced: true,
  state: {
    category: null,
  },
  getters: {
    category(state) {
      return state.category;
    },
  },
  mutations: {
    SET_CATEGORY(state, data) {
        state.category = data;
      },
  
  },
  actions: {
    async fetchCategory({commit}) {
        const response = await axios.get('action/get_category');

        commit('SET_CATEGORY', response.data);
    },

  }
};
