import axios from "axios";
import ability from '../services/ability';

export default {
  namespaced: true,
  state: {
    role: null,
  },
  getters: {
    roles(state) {
      return state.role;
    },
  },
  mutations: {
    SET_ROLES(state, role) {
      state.role = role;
    },
    ADD_ROLE(state, data){
      let items = state.role.concat(data);
      state.role = items;
    }
  },
  actions: {
    async getRoles({commit}) {
        return axios
        .get("option/get_roles")
        .then((response) => {
            commit('SET_ROLES', response.data);
        })
    },

    async addRole({commit, dispatch}, data) {
        await axios.post("option/add_roles", data).then(response => {
          dispatch('notification/addNotification', {
            type: 'success',
            message: 'Successfully Added!'
          }, {root: true});

          commit('ADD_ROLE', response.data);
        }, () => {

          dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Ops! Something went wrong!'
          }, {root: true});
          
        });
    },

    async editRole({commit, dispatch}, data) {
      return new Promise((resolve, reject) => {
          axios.post("option/edit_roles", data).then(response => {
              dispatch('notification/addNotification', {
                type: 'success',
                message: 'Successfully Updated!'
              }, {root: true});

              let permissions = [];
              data.permissions.forEach(item => {
                if(item.permission){
                  permissions.push({action: item.permission.action, page: item.permission.page, key: item.permission.permission_key})
                }
              });

              dispatch('getRoles');

                resolve(response.data);
            }, error => {
              dispatch('notification/addNotification', {
                type: 'danger',
                message: 'Ops! Something went wrong!'
              }, {root: true});
                reject(error);
            });
      })
    },

    async updatePermission({commit, dispatch}, data){
      let permissions = [];
      let permission = [];
      data.permissions.forEach(item => {
        if(item.permission){
          permissions.push({action: item.permission.action, page: item.permission.page, key: item.permission.permission_key})
          permission.push({action: item.permission.action, subject: item.permission.permission_key})
        }
      });

      ability.update(permission);

      localStorage.setItem("permissions", JSON.stringify(permissions));
      
    },

    async removeRoles({commit, dispatch}, data) {
      await axios.post("option/delete_roles", {id: data}).then(response => {
        dispatch('notification/addNotification', {
          type: 'success',
          message: 'Successfully Deleted!'
        }, {root: true});
        
        dispatch('getRoles');
      }, () => {

        dispatch('notification/addNotification', {
          type: 'danger',
          message: 'Ops! Something went wrong!'
        }, {root: true});
        
      });
    },

  }
};
