import axios from "axios";

export default {
  namespaced: true,
  state: {
    activity_log: null,
  },
  mutations: {
    SET_ACTIVITY_LOG(state, data) {
        state.activity_log = data;
    },
    
    NEW_ACTIVITY_LOG(state, data) {
        let items = state.activity_log.concat(data);
        state.activity_log = items;
    },
  },
  actions: {
    async fetchActivityLogs({commit}) {
        const response = await axios.get('action/activity_log');

        commit('SET_ACTIVITY_LOG', response.data);
    },

    async addActivityLog({commit}, payload) {
        try{
            const response = await axios.post('action/activity_log', payload);
            commit('NEW_ACTIVITY_LOG', response.data);
        }catch{
            console.log('error log');
        }
    }

  }
};
