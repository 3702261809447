import axios from "axios";

export default {
  namespaced: true,
  state: {
    salary: null
  },
  getters: {
    salary(state) {
      return state.salary;
    },
  },
  mutations: {
    SET_SALARY(state, data) {
        state.salary = data;
      },
  },
  actions: {
    async findSalary({commit}, data) {
        await axios.get(`action/find_salary/${data.id}/date?start=${data.start}&end=${data.end}`).then(response => {
          commit('SET_SALARY', response.data);
        });
    },

    async findSize({commit}, id) {
      const response = await axios.get(`action/get_size_id/${id}`);
      commit('SET_SIZE_ID', response.data);
  },
  }
};
