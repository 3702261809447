import axios from "axios";

export default {
  namespaced: true,
  state: {
    item: null,
  },
  getters: {
    item(state) {
      return state.item;
    },
  },
  mutations: {
    SET_ITEM(state, data) {
        state.item = data;
    },
    
    NEW_ITEM(state, data) {
      let items = state.item.concat(data);
      state.item = items;
    },

    UPDATE_ITEM(state, data){
        const index = state.item.findIndex(item => item.id === data.id);
        if(index !== -1){
          state.item.splice(index, 1, data);
        }
    },

    REMOVE_ITEM(state, id) {
      let item = state.item.filter(veh => veh.id != id);
      state.item = item;
    },
  },
  actions: {
    async fetchItem({commit, rootState}) {
        const response = await axios.get(`action/get_item`);

        commit('SET_ITEM', response.data);
    },

    async addItem({commit, dispatch}, data) {
        await axios.post("action/add_item", data).then(response => {
          dispatch('notification/addNotification', {
            type: 'success',
            message: 'Successfully Added!'
          }, {root: true});
          
          dispatch('activity_log/addActivityLog', {
            activity: 'added item ' + response.data.brand + ' ' + response.data.particular
          }, {root: true});
          dispatch('inventory/fetchNotifierInventory', {}, {root: true});
          commit('NEW_ITEM', response.data);
        }, () => {
          dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Ops! Something went wrong!'
          }, {root: true});
        });
    },

    async updateItem({commit, dispatch}, payload) {
        await axios.put("action/update_item", payload).then(response => {
          dispatch('notification/addNotification', {
            type: 'success',
            message: 'Successfully Updated!'
          }, {root: true});
          dispatch('inventory/fetchNotifierInventory', {}, {root: true});
          
          commit('UPDATE_ITEM', response.data);
        }, () => {
          dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Ops! Something went wrong!'
          }, {root: true});
        });
    },

    async removeItem({commit, dispatch}, id) {
        await axios.delete(`action/delete_item/${id}`).then(response => {
          dispatch('notification/addNotification', {
            type: 'success',
            message: 'Successfully Deleted!'
          }, {root: true});
          dispatch('inventory/fetchNotifierInventory', {}, {root: true});
          commit('REMOVE_ITEM', response.data);
        }, () => {
          dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Ops! Something went wrong!'
          }, {root: true});
        });
    },
  }
};
