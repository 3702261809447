import axios from "axios";

export default {
  namespaced: true,
  state: {
    inventory: [],
    notifier_inventory: []
  },
  mutations: {
    SET_INVENTORY(state, data) {
        state.inventory = data;
    },
    SET_NOTIFIER_INVENTORY(state, data) {
      state.notifier_inventory = data;
  },
  },
  actions: {
    async fetchInventory({commit}) {
        const response = await axios.get('action/inventory');
        console.log(response);
        commit('SET_INVENTORY', response.data);
    },

    async fetchNotifierInventory({commit}) {
        try{
          const response = await axios.get('action/notifier_inventory');
          commit('SET_NOTIFIER_INVENTORY', response.data);
        }catch(e){
          console.log(e);
        }
    },

    async checkInventory({commit}) {
      const response = await axios.get('action/check_inventory');
    }
  }
};
