import axios from "axios";

export default {
  namespaced: true,
  state: {
    users: null,
  },
  getters: {
    users(state) {
      return state.users;
    },
  },
  mutations: {
    SET_USERS(state, data) {
        state.users = data;
    },
  
  },
  actions: {
    async fetchUsers({commit}, id) {
        const response = await axios.get(`action/get_user/${id}`);

        commit('SET_USERS', response.data);
    },

    async updateUsers({dispatch},data) {
        await axios.post("action/update_profile", data).then(response => {
          dispatch('notification/addNotification', {
            type: 'success',
            message: 'Successfully Updated!'
          }, {root: true});

          dispatch('fetchUsers', response.data);
          dispatch('auth/updateuser', '', { root: true });
        }, error => {
          console.log(error);
          dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Ops! Something went wrong!'
          }, {root: true});
          
        });
    },

    async updatePassword({dispatch},data) {
        await axios.post("action/update_password", data).then(response => {
          dispatch('notification/addNotification', {
            type: 'success',
            message: 'Successfully Updated!'
          }, {root: true});

          dispatch('activity_log/addActivityLog', {
            activity: 'has changed password'
          }, {root: true});

          dispatch('fetchUsers', response.data);
        }, error => {
          console.log(error);
          dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Ops! Something went wrong!'
          }, {root: true});
          
        });
    },

    async updatePicture({dispatch},data) {

      await axios.post("action/update_picture", data.formData, data.config).then(response => {
        dispatch('notification/addNotification', {
          type: 'success',
          message: 'Successfully Updated!'
        }, {root: true});

        dispatch("fetchUsers", response.data);
        dispatch('auth/updateuser', '', { root: true });
      }, error => {
        console.log(error);
        dispatch('notification/addNotification', {
          type: 'danger',
          message: 'Ops! Something went wrong!'
        }, {root: true});
        
      });
    },
  }
};
