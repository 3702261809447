import axios from "axios";

export default {
  namespaced: true,
  state: {
    shift: null,
    incentives: null
  },
  getters: {
    shift(state) {
      return state.shift;
    },

    incentives(state) {
      return state.incentives;
    },
  },
  mutations: {
    SET_SHIFT(state, data) {
        state.shift = data;
    },

    SET_INCENTIVES(state, data) {
        state.incentives = data;
    },

    NEW_SHIFT(state, data) {
        let shifts = state.shift.concat(data);
        state.shift = shifts;
    },

    NEW_INCENTIVES(state, data) {
      let items = state.incentives.concat(data);
      state.incentives = items;
    },

    UPDATE_SHIFT(state, data){
      const index = state.shift.findIndex(item => item.id === data.id);
      if(index !== -1){
        state.shift.splice(index, 1, data);
      }
    },

    REMOVE_SHIFT(state, id){
      let shifts = state.shift.filter(veh => veh.id != id);
      state.shift = shifts;
    },

    REMOVE_INCENTIVES(state, id){
      let incentive = state.incentives.filter(veh => veh.id != id);
      state.incentives = incentive;
    }
    
  },
  actions: {
    async fetchShift({commit, rootState}) {
        const response = await axios.get(`action/get_shift/${rootState.auth.user.branch_id}`);

        commit('SET_SHIFT', response.data);
    },

    async fetchIncentives({commit}, id) {
        const response = await axios.get(`action/get_incentives/${id}`);
        commit('SET_INCENTIVES', response.data);
    },

    async addShift({dispatch, commit, rootState}, data) {
        await axios.post("action/add_shift", {
            name: data.data.name,
            working_calculated: data.data.calculated,
            late_allowance: data.data.late_allowance,
            start_worktime: data.data.worktime.start,
            end_worktime: data.data.worktime.end,
            valid_checkin_from: data.data.valid_checkin.from,
            valid_checkin_to: data.data.valid_checkin.to,
            valid_checkout_from: data.data.valid_checkout.from,
            valid_checkout_to: data.data.valid_checkout.to,
            default_incentives: data.data.default_incentives,
            branch_id: rootState.auth.user.branch_id,
            benefits: data.data.benefits,
            benefit: data.benefits
        }).then(response => {
          dispatch('notification/addNotification', {
            type: 'success',
            message: 'Successfully Added!'
          }, {root: true});
          commit('NEW_SHIFT', response.data);
        }, () => {
          dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Ops! Something went wrong!'
          }, {root: true});
          
        });
    },

    async addIncentives({dispatch, commit}, data) {
        await axios.post("action/add_incentives", {
            shift_id: data.id,
            services_id: data.services_id.value,
            percentage: data.percentage,
        }).then(response => {
          dispatch('notification/addNotification', {
            type: 'success',
            message: 'Successfully Added!'
          }, {root: true});
          console.log(response.data);
          commit('NEW_INCENTIVES', response.data);
        }, () => {
          dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Ops! Something went wrong!'
          }, {root: true});
          
        });
    },

    async updateShift({dispatch, commit, rootState}, data) {
        await axios.put("action/update_shift", {
            id: data.data.id,
            name: data.data.name,
            working_calculated: data.data.calculated,
            late_allowance: data.data.late_allowance,
            start_worktime: data.data.worktime.start,
            end_worktime: data.data.worktime.end,
            valid_checkin_from: data.data.valid_checkin.from,
            valid_checkin_to: data.data.valid_checkin.to,
            valid_checkout_from: data.data.valid_checkout.from,
            valid_checkout_to: data.data.valid_checkout.to,
            default_incentives: data.data.default_incentives,
            branch_id: rootState.auth.user.branch_id,
            benefits: data.data.benefits,
            benefit: data.benefits
        }).then(response => {
          commit('UPDATE_SHIFT', response.data);
          dispatch('notification/addNotification', {
            type: 'success',
            message: 'Successfully Updated!'
          }, {root: true});
        }, () => {
          dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Ops! Something went wrong!'
          }, {root: true});
          
        });
    },

    async removeShift({ dispatch, commit }, data) {
        await axios.delete(`action/delete_shift/${data}`).then(response => {
          dispatch('notification/addNotification', {
            type: 'success',
            message: 'Successfully Deleted!'
          }, {root: true});

          commit('REMOVE_SHIFT', response.data);
        }, () => {
          dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Ops! Something went wrong!'
          }, {root: true});
          
        });
    },

    async removeIncentives({ dispatch, commit }, data) {
      await axios.delete(`action/delete_incentives/${data}`).then(response => {
        dispatch('notification/addNotification', {
          type: 'success',
          message: 'Successfully Deleted!'
        }, {root: true});

        commit('REMOVE_INCENTIVES', response.data);
      }, () => {
        dispatch('notification/addNotification', {
          type: 'danger',
          message: 'Ops! Something went wrong!'
        }, {root: true});
        
      });
  },
    
  }
};
