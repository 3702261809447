import axios from "axios";

export default {
  namespaced: true,
  state: {
    stockout: []
  },
  getters: {
    stockout(state) {
      return state.stockout;
    },
    temp_stockout(state){
      return state.temp_stockout;
    }
  },
  mutations: {
    SET_STOCKOUT(state, data) {
        state.stockout = data;
    },
    
    NEW_STOCKOUT(state, data) {
      let items = state.stockout.concat(data);
      state.stockout = items;
    },

    UPDATE_STOCKOUT(state, data){
        const index = state.stockout.findIndex(item => item.id === data.id);
        if(index !== -1){
          state.stockout.splice(index, 1, data);
        }
    },

    REMOVE_STOCKOUT(state, id) {
      let item = state.stockout.filter(veh => veh.id != id);
      state.stockout = item;
    },
  },
  actions: {
    async fetchStockout({commit, rootState}) {
        const response = await axios.get(`action/stockout`);
        commit('SET_STOCKOUT', response.data);
    },

    async addStockout({commit, dispatch}, payload) {
        await axios.post("action/stockout", payload).then(response => {
          dispatch('notification/addNotification', {
            type: 'success',
            message: 'Successfully Added!'
          }, {root: true});
          
          commit('NEW_STOCKOUT', response.data);
        }, () => {
          dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Ops! Something went wrong!'
          }, {root: true});
        });
    },

    async updateStockout({commit, dispatch}, payload) {
        await axios.put(`action/stockout/${payload.id}`, payload).then(response => {
          dispatch('notification/addNotification', {
            type: 'success',
            message: 'Successfully Updated!'
          }, {root: true});
          
          console.log(response.data);
          commit('UPDATE_STOCKOUT', response.data);
        }, () => {
          dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Ops! Something went wrong!'
          }, {root: true});
        });
    },

    async removeStockout({commit, dispatch}, id) {
        await axios.delete(`action/stockout/${id}`).then(response => {
          dispatch('notification/addNotification', {
            type: 'success',
            message: 'Successfully Deleted!'
          }, {root: true});
          
          commit('REMOVE_STOCKOUT', parseInt(response.data));
        }, () => {
          dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Ops! Something went wrong!'
          }, {root: true});
        });
    },
  }
};
