import axios from "axios";

export default {
  namespaced: true,
  state: {
    chart: null,
    sales_widget: null,
    chart_target: null,
    member_widget: null,
    expenses_widget: null
  },
  getters: {
    chart(state) {
      return state.chart;
    },
    chart_target(state) {
      return state.chart_target;
    },
    sales_widget(state){
      return state.sales_widget;
    },
    members_widget(state){
      return state.member_widget;
    },
    expenses_widget(state){
      return state.expenses_widget;
    },
  },
  mutations: {
    SET_CHART(state, data) {
        state.chart = data;
      },
    SET_CHART_TARGET(state, data) {
      state.chart_target = data;
    },
    SET_SALES_WIDGET(state, data) {
    state.sales_widget = data;
    },
    SET_MEMBERS_WIDGET(state, data) {
      state.member_widget = data;
    },
    SET_EXPENSES_WIDGET(state, data) {
      state.expenses_widget = data;
    },
  },
  actions: {
    async fetchChart({commit}, year) {
        const response = await axios.get(`action/get_chart/${year}`);
        console.log(year);
        commit('SET_CHART', response.data);
    },

    async fetchSalesWedgit({commit}, data) {
        
        const response = await axios.post('action/get_sales', {
            data: data
        });
        
        commit('SET_SALES_WIDGET', response.data);
    },

    async fetchMemberWedgit({commit, rootState}, data) {
        
        const response = await axios.post('action/get_members_count', {
            data: data,
            branch_id: rootState.auth.user.branch_id
        });
        
        commit('SET_MEMBERS_WIDGET', response.data);
    },

    async fetchExpensesWedgit({commit, rootState}, data) {
        
      const response = await axios.post('action/get_expenses_count', {
          data: data,
          branch_id: rootState.auth.user.branch_id
      });
      
      commit('SET_EXPENSES_WIDGET', response.data);
  },

  }
};
