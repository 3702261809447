import axios from "axios";

export default {
  namespaced: true,
  state: {
    purchase_order: []
  },
  mutations: {
    SET_PURCHASE_ORDER(state, data) {
        state.purchase_order = data;
    },

    NEW_PURCHASE_ORDER(state, data) {
        let purchase_order = state.purchase_order.concat(data);
        state.purchase_order = purchase_order;
    },

    UPDATE_PURCHASE_ORDER(state, data){
        const index = state.purchase_order.findIndex(item => item.id === data.id);
        if(index !== -1){
          state.purchase_order.splice(index, 1, data);
        }
    },

    REMOVE_PURCHASE_ORDER(state, id) {
      let item = state.purchase_order.filter(veh => veh.id != id);
      state.purchase_order = item;
    },
  },
  actions: {
    async fetchPurchaseOrder({commit}) {
        const response = await axios.get('action/purchase_order');
        console.log(response.data);
        commit('SET_PURCHASE_ORDER', response.data);
    },

    async addPurchaseOrder({commit, dispatch}, payload) {
      await axios.post("action/purchase_order", payload).then(response => {
        dispatch('notification/addNotification', {
          type: 'success',
          message: 'Successfully Added!'
        }, {root: true});
        console.log(response.data);
        commit('NEW_PURCHASE_ORDER', response.data);
      }, () => {
        dispatch('notification/addNotification', {
          type: 'danger',
          message: 'Ops! Something went wrong!'
        }, {root: true});
      });
    },

    async addOperationPurchaseOrder({commit, dispatch}, payload) {
      await axios.post("action/add_operation_purchase_order", payload).then(response => {
        dispatch('notification/addNotification', {
          type: 'success',
          message: 'Successfully Added!'
        }, {root: true});
        
        // commit('NEW_UNIT', response.data);
      }, () => {
        dispatch('notification/addNotification', {
          type: 'danger',
          message: 'Ops! Something went wrong!'
        }, {root: true});
      });
    },

    async updateDeliveredStatusPurchaseOrder({commit, dispatch}, payload) {
      await axios.put(`action/purchase_order_delivered/${payload.id}`, payload).then(response => {
        dispatch('notification/addNotification', {
          type: 'success',
          message: 'Successfully Updated!'
        }, {root: true});
        
        commit('UPDATE_PURCHASE_ORDER', response.data);
      }, () => {
        dispatch('notification/addNotification', {
          type: 'danger',
          message: 'Ops! Something went wrong!'
        }, {root: true});
      });
    },

    async removePurchaseOrder({commit, dispatch}, id) {
      await axios.delete(`action/purchase_order/${id}`).then(response => {
        dispatch('notification/addNotification', {
          type: 'success',
          message: 'Successfully Deleted!'
        }, {root: true});
        
        commit('REMOVE_PURCHASE_ORDER', response.data);
      }, () => {
        dispatch('notification/addNotification', {
          type: 'danger',
          message: 'Ops! Something went wrong!'
        }, {root: true});
      });
    },

    async getPoNumber({commit}, id) {
      const response = await axios.get(`action/po_no`);

      return response.data;
    },
    
  }
};
