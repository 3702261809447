
import axios from "axios";

export default {
  namespaced: true,
  state: {
    notifications: [],
    notification_message: []
  },
  getters: {
    notifications(state) {
      return state.notifications;
    },
    notification_message(state){
      return state.notification_message;
    }
  },
  mutations: {
    PUSH_NOTIFICATION(state, notification) {
      state.notifications.push({
          ...notification,
          id: (Math.random().toString(36) + Date.now().toString(36)).substr(2)
      });
    },
    SET_NOTIFICATION(state, data){
      state.notification_message = data;
    },
    UPDATE_NOTIFICATION(state, data){
      const index = state.notification_message.findIndex(item => item.id === data.id);
      if(index !== -1){
        state.notification_message.splice(index, 1, data);
      }
    },
    NEW_NOTIFICATION(state, data){
      let items = state.notification_message.concat(data);
      state.notification_message = items;
    },
  },
  actions: {
    addNotification({commit}, notification){
        commit('PUSH_NOTIFICATION', notification);
    },

    async fetchNotification({commit, rootState}) {
        const response = await axios.get(`action/get_notification/${rootState.auth.user.branch_id}`);
        
        commit('SET_NOTIFICATION', response.data);
    },

    async updateNotification({commit, rootState}) {
      await axios.put("action/update_notification", {
        branch_id: rootState.auth.user.branch_id
      }).then(response => {

        commit('SET_NOTIFICATION', response.data);
      }, error => {
        console.log(error);
        
      });
        
    },

    async readNotification({commit, rootState}, id) {
      await axios.put("action/read_notification", {
        branch_id: rootState.auth.user.branch_id,
        user_id: rootState.auth.user.id,
        notification_id: id
      }).then(response => {

        commit('UPDATE_NOTIFICATION', response.data);
      }, error => {
        console.log(error);
        
      });
        
    },
  }
};
