<template>
<div>
  <router-view :key="componentKey"></router-view>
  <notifications-list />
  <loading-panel />
  <PermissionModal/>
</div>
</template>

<script>
import NotificationsList from './containers/NotificationsList';
import LoadingPanel from './containers/LoadingPanel';
import PermissionModal from './containers/PermissionModal';

export default {
  name: 'App',
  data() {
    return {
      componentKey: Math.random()
    }
  },
  components: {
    NotificationsList,
    LoadingPanel,
    PermissionModal
  },
  created(){
  }
  // beforeCreate(){
  //   console.log('Hello map page bfc');
  // },
  // beforeMount(){
  //   console.log('Hello map page bfm');
  // },
  // mount(){
  //   console.log('Hello map page m');
  // },
  // beforeUnmount(){
  //   console.log('Hello map page bfum');
  // },
  // beforebeforeDestroy(){
  //   console.log('Hello map page bfd');
  // },
  // destroy(){
  //   console.log('Hello map page des');
  // }
}
</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';
</style>
