import axios from "axios";

export default {
  namespaced: true,
  state: {
    operation_purchase_order: []
  },
  mutations: {
    SET_OPERATION_PURCHASE_ORDER(state, data) {
        state.operation_purchase_order = data;
    },

    NEW_OPERATION_PURCHASE_ORDER(state, data) {
        let operation_purchase_order = state.operation_purchase_order.concat(data);
        state.operation_purchase_order = operation_purchase_order;
    },

    UPDATE_OPERATION_PURCHASE_ORDER(state, payload){
        let operationToUpdate = state.operation_purchase_order.find(item => item.id === payload.id)
        operationToUpdate = payload;
    },

    REMOVE_OPERATION_PURCHASE_ORDER(state, id){
        state.operation_purchase_order = state.operation_purchase_order.filter(item => item.id !== id)
    }
  },
  actions: {
    async fetchOperationPurchaseOrder({commit}) {
        const response = await axios.get('action/operation_purchase_order');
        console.log(response);
        commit('SET_OPERATION_PURCHASE_ORDER', response.data);
    },

    async addOperationPurchaseOrder({commit, dispatch}, payload) {
      await axios.post("action/operation_purchase_order", payload).then(response => {
        dispatch('notification/addNotification', {
          type: 'success',
          message: 'Successfully Added!'
        }, {root: true});
        
        commit('NEW_OPERATION_PURCHASE_ORDER', response.data);
      }, () => {
        dispatch('notification/addNotification', {
          type: 'danger',
          message: 'Ops! Something went wrong!'
        }, {root: true});
      });
    },

    async updateOperationPurchaseOrder({commit, dispatch}, payload) {
        await axios.put(`action/operation_purchase_order/${payload.id}`, payload).then(response => {
            dispatch('notification/addNotification', {
            type: 'success',
            message: 'Successfully Updated!'
            }, {root: true});
            
            dispatch('fetchOperationPurchaseOrder');
        }, () => {
            dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Ops! Something went wrong!'
            }, {root: true});
        });
    },

    async updateStatusOperationPurchaseOrder({commit, dispatch}, payload) {
        await axios.put(`action/operation_purchase_order_status/${payload.id}`, payload).then(response => {
            dispatch('notification/addNotification', {
            type: 'success',
            message: 'Successfully Updated!'
            }, {root: true});
            
            dispatch('fetchOperationPurchaseOrder');
        }, () => {
            dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Ops! Something went wrong!'
            }, {root: true});
        });
    },

    async updateConvertStatusOperationPurchaseOrder({commit, dispatch}, payload) {
        await axios.put(`action/operation_purchase_order_convert_status/${payload.id}`, payload).then(response => {
            dispatch('notification/addNotification', {
            type: 'success',
            message: 'Successfully Updated!'
            }, {root: true});
            
            dispatch('fetchOperationPurchaseOrder');
        }, () => {
            dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Ops! Something went wrong!'
            }, {root: true});
        });
    },

    async removeOperationPurchaseOrder({commit, dispatch}, id){
        await axios.delete(`action/operation_purchase_order/${id}`).then(response => {
            dispatch('notification/addNotification', {
                type: 'success',
                message: 'Successfully Deleted!'
            }, {root: true});
            commit('REMOVE_OPERATION_PURCHASE_ORDER', parseInt(response.data));
            }, () => {
            dispatch('notification/addNotification', {
                type: 'danger',
                message: 'Ops! Something went wrong!'
            }, {root: true});
        });
    },

    async getOperationPoNumber({commit}, id) {
      const response = await axios.get(`action/po_no`);

      return response.data;
    },
    
  }
};
