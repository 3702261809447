import axios from "axios";

export default {
  namespaced: true,
  state: {
    services: [],
  },
  getters: {
    services(state) {
      return state.services;
    },
  },
  mutations: {
      SET_SERVICES(state, data) {
        state.services = data;
      },
  
      NEW_SERVICES(state, data) {
        let items = state.services.concat(data);
        state.services = items;
      },
  
      UPDATE_SERVICES(state, data) {
        state.services.forEach(v => {
          if(v.id == data.id){
            v = data;
          }
        });
      },
  
      REMOVE_SERVICES(state, id) {
        let services = state.services.filter(veh => veh.id != id);
        state.services = services;
      },
  },
  actions: {
    async fetchServices({commit}) {
        const response = await axios.get('action/get_services');

        commit('SET_SERVICES', response.data);
    },

    async getMainServices({commit}) {
        const response = await axios.get('action/get_main_services');

       return response.data;
    },

    async findServices({commit}, data) {
      const response = await axios.post('action/find_services', {
        size_id: data.size_id,
        category_id: data.category_id.value,
      });
      
      commit('SET_SERVICES', response.data);
    },

    async getVariant({commit}, id) {
      const response = await axios.get(`action/get_variant/${id}`);
      
      return response.data;
    },

    async nullServices({commit}) {
      commit('SET_SERVICES', null);
    },

    async saveVariant({dispatch}, data) {
        await axios.post("action/save_variant", {
          id: data[0],
          price: data[1]
        }).then(() => {
          dispatch('notification/addNotification', {
            type: 'success',
            message: 'Successfully Saved!'
          }, {root: true});
          return dispatch('fetchServices');
        }, error => {
          console.log(error);
          dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Ops! Something went wrong!'
          }, {root: true});
          
        });
    },

    async addServices({commit, dispatch}, data) {
      console.log(data);
      await axios.post("action/add_services", {
        form: data
      }).then(response => {
        dispatch('notification/addNotification', {
          type: 'success',
          message: 'Successfully Added!'
        }, {root: true});
        
        console.log(response.data);
        commit('NEW_SERVICES', response.data);
      }, error => {
        console.log(error);
        dispatch('notification/addNotification', {
          type: 'danger',
          message: 'Ops! Something went wrong!'
        }, {root: true});
        
      });
    },

    async updateServices({commit,dispatch},data) {
      await axios.post("action/update_vehicle", {
          form: data
      }).then(response => {
        dispatch('notification/addNotification', {
          type: 'success',
          message: 'Successfully Updated!'
        }, {root: true});
        console.log(response.data.form);
        commit('UPDATE_VEHICLE', response.data.form);
      }, error => {
        console.log(error);
        dispatch('notification/addNotification', {
          type: 'danger',
          message: 'Ops! Something went wrong!'
        }, {root: true});
        
      });
        
    },

    async updateEditServices({commit,dispatch},data) {
      await axios.post("action/update_custom_services", {
          form: data
      }).then(() => {
        dispatch('notification/addNotification', {
          type: 'success',
          message: 'Successfully Updated!'
        }, {root: true});
        
        dispatch('fetchServices');
      }, error => {
        console.log(error);
        dispatch('notification/addNotification', {
          type: 'danger',
          message: 'Ops! Something went wrong!'
        }, {root: true});
        
      });
        
    },

    async removeServices({ commit,dispatch }, data) {
      await axios.post("action/delete_services", {
          id: data
      }).then(response => {
        dispatch('notification/addNotification', {
          type: 'success',
          message: 'Successfully Deleted!'
        }, {root: true});

        commit('REMOVE_SERVICES', response.data);
      }, error => {
        console.log(error);
        dispatch('notification/addNotification', {
          type: 'danger',
          message: 'Ops! Something went wrong!'
        }, {root: true});
      });
    },

    async syncServices({dispatch}, payload) {
      await axios.post("action/sync_services", payload).then(response => {
        dispatch('notification/addNotification', {
          type: 'success',
          message: 'Successfully Sync!'
        }, {root: true});
        dispatch('fetchServices');
      }, error => {
        console.log(error);
        dispatch('notification/addNotification', {
          type: 'danger',
          message: 'Ops! Something went wrong!'
        }, {root: true});
      });
    },

  }
};
