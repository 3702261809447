import axios from "axios";

export default {
  namespaced: true,
  state: {
    members: null,
    property: null,
    searched_members: null,
    member_profile: null,
    searched_property: null
  },
  getters: {
    members(state) {
      return state.members;
    },
    property(state) {
      return state.property;
    },
    searched_members(state) {
      return state.searched_members;
    },
    member_profile(state) {
      return state.member_profile;
    },
    searched_property(state) {
      return state.searched_property;
    },
  },
  mutations: {
      SET_MEMBERS(state, data) {
        state.members = data;
      },

      SEARCH_MEMBERS(state, data){
        state.searched_members = data;
      },

      SET_SEARCH_PROPERTY(state, data){
        state.searched_property = data;
      },

      SET_PROPERTY(state, data) {
        state.property = data;
      },

      SET_MEMBER_PROFILE(state, data) {
        state.member_profile = data;
      },
  
      NEW_MEMBERS(state, data) {
        let items = state.members.concat(data);
        state.members = items;
      },
  
      UPDATE_MEMBERS(state, payload) {
        let itemToUpdate = state.members.find(item => item.id === payload.id)
        itemToUpdate = payload
      },
  
      REMOVE_MEMBERS(state, id) {
        let members = state.members.filter(member => member.id != id);
        state.members = members;
      },
  },
  actions: {
    async fetchMembers({commit, rootState}) {
        const response = await axios.get(`action/get_members/${rootState.auth.user.branch_id}`);
        
        commit('SET_MEMBERS', response.data);
    },

    async MemberProfile({commit, rootState}, id) {
        const response = await axios.get(`action/member_profile/${rootState.auth.user.branch_id}/${id}`);
        
        commit('SET_MEMBER_PROFILE', response.data);
    },
    async SearchMemberProperty({commit, rootState}, data) {
      console.log(data);
        const response = await axios.get(`action/member_property/${rootState.auth.user.branch_id}/${data.member_id}/${data.vehicle_id}`);
        
        commit('SET_SEARCH_PROPERTY', response.data);
    },
    
    async searchMembers({commit, rootState}, data) {
        const response = await axios.get(`action/search_member/${rootState.auth.user.branch_id}/${data}`);
        
        const merge = response.data.members.concat(response.data.personnel)
        
        commit('SEARCH_MEMBERS', merge);
    },

    async findProperty({commit}, data) {
        const response = await axios.post('action/find_property', data);

        console.log(response.data);
        commit('SET_PROPERTY', response.data);
    },

    async addMembers({commit, dispatch, rootState}, data) {
      await axios.post("action/add_member", {
          form: data,
          branch_id: rootState.auth.user.branch_id
      }).then(response => {
        dispatch('notification/addNotification', {
          type: 'success',
          message: 'Successfully Added!'
        }, {root: true});

        commit('NEW_MEMBERS', response.data);
      }, error => {
        console.log(error);
        dispatch('notification/addNotification', {
          type: 'danger',
          message: 'Ops! Something went wrong!'
        }, {root: true});
        
      });
    },

    async deletePropertyMembers({dispatch}, id) {
      await axios.get(`action/delete_property_member/${id}`).then(() => {
        dispatch('notification/addNotification', {
          type: 'success',
          message: 'Successfully Deleted!'
        }, {root: true});

        dispatch('fetchMembers');
      }, error => {
        console.log(error);
        dispatch('notification/addNotification', {
          type: 'danger',
          message: 'Ops! Something went wrong!'
        }, {root: true});
        
      });
    },

    async updateMembers({dispatch},data) {
      await axios.post("action/update_member", {
        form: data
      }).then(() => {
        dispatch('notification/addNotification', {
          type: 'success',
          message: 'Successfully Updated!'
        }, {root: true});

        dispatch('activity_log/addActivityLog', {
          activity: 'updated ' + data.name + ' Information'
        }, {root: true});

        dispatch('fetchMembers');
      }, error => {
        console.log(error);
        dispatch('notification/addNotification', {
          type: 'danger',
          message: 'Ops! Something went wrong!'
        }, {root: true});
        
      });
        
    },

    async renewMembers({dispatch}, payload) {
      await axios.post("action/renew_member", payload).then(() => {
        dispatch('notification/addNotification', {
          type: 'success',
          message: 'Successfully Renew!'
        }, {root: true});
        console.log(payload);
        dispatch('activity_log/addActivityLog', {
          activity: 'renewed ' + payload.name
        }, {root: true});

        dispatch('fetchMembers');
      }, error => {
        console.log(error);
        dispatch('notification/addNotification', {
          type: 'danger',
          message: 'Ops! Something went wrong!'
        }, {root: true});
        
      });
        
    },

    async removeMembers({ commit,dispatch }, id) {
      await axios.get(`action/delete_members/${id}`).then(response => {
        dispatch('notification/addNotification', {
          type: 'success',
          message: 'Successfully Deleted!'
        }, {root: true});

        commit('REMOVE_MEMBERS', response.data);
      }, error => {
        console.log(error);
        dispatch('notification/addNotification', {
          type: 'danger',
          message: 'Ops! Something went wrong!'
        }, {root: true});
        
      });
    },

  }
};
