import axios from "axios";

export default {
  namespaced: true,
  state: {
    incentives: null,
    name: null
  },
  getters: {
    incentives(state) {
      return state.incentives;
    },
    name(state) {
        return state.name;
    },
  },
  mutations: {
    SET_INCENTIVES(state, data) {
        state.incentives = data;
    },

    SET_NAME(state, data) {
        state.name = data;
    },
  },
  actions: {
    async findIncentives({commit}, data) {
        const response = await axios.post('action/find_incentives', data);
        const array = [];
        let name = '';

        for(let i = 0; i< response.data.length; i++){
            for(let v = 0; v < response.data[i].labors.length; v++){
                if(response.data[i].labors[v].washboy_id == data.id){
                    array.push(response.data[i])
                    name = response.data[i].labors[v].washboy.name;
                }
            }
        }
        
        commit('SET_INCENTIVES', array);
        commit('SET_NAME', name);
    },
  }
};
