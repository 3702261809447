import axios from "axios";

export default {
  namespaced: true,
  state: {
    permission: null,
    permission_modal: false,
    permission_modal_data: null,
    permission_settings: false
  },
  getters: {
    permission(state) {
      return state.permission;
    },
  },
  mutations: {
    SET_PERMISSION(state, data) {
      state.permission = data;
    },
    TOGGLE_PERMISSION(state) {
      console.log(state.permission_settings);
      state.permission_settings = !state.permission_settings;
    },
    SET_MODAL_CLOSE(state, data) {
      state.permission_modal = data;
    },
    SET_MODAL_PERMISSION_DATA(state, data) {
      state.permission_modal_data = data;
    },
  },
  actions: {
    async fetchPermission({commit, rootState}) {
         const response = await axios.post('action/get_permission', {
            id : rootState.auth.user.role_id
         });
         
         commit('SET_PERMISSION', response.data);
         return response.data;
    },

    async updatePermission({dispatch}, data) {
      await axios.post("action/update_permission", data).then(response => {
        dispatch('notification/addNotification', {
          type: 'success',
          message: 'Successfully Updated!'
        }, {root: true});

        dispatch('fetchPermission');
        dispatch('option/getRoles', {}, {root: true});
        
      }, () => {
        dispatch('notification/addNotification', {
          type: 'danger',
          message: 'Ops! Something went wrong!'
        }, {root: true});
        
      });
    },

    async getPermission({commit}, key) {
      const response = await axios.get(`action/get_permission_details/${key}`, {
          permission_key : key
      });
      
      commit('SET_MODAL_CLOSE', false);
      return response.data;
    },

    async submitElementPermission({commit, dispatch}, data){
      await axios.post("action/submit_element_permission", data).then(response => {
        dispatch('notification/addNotification', {
          type: 'success',
          message: 'Successfully Updated!'
        }, {root: true});

        // dispatch('fetchPermission');
        // dispatch('option/getRoles', {}, {root: true});
        
      }, () => {
        dispatch('notification/addNotification', {
          type: 'danger',
          message: 'Ops! Something went wrong!'
        }, {root: true});
        
      });
    },

    async permissionModalOpen({commit}, data){
        commit('SET_MODAL_CLOSE', true);
        commit('SET_MODAL_PERMISSION_DATA', data);
    },

    async permissionModalClose({commit}) {
      commit('SET_MODAL_CLOSE', false);
    },

  }
};
