import 'core-js/stable'
import Vue from 'vue'
//import CoreuiVue from '@coreui/vue/src'
import CoreuiVuePro from '@coreui/vue-pro/src/index.js'
import App from './App'
import router from './router/index'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store/index'
import axios from 'axios'
import VCalendar from 'v-calendar';
import VueHtmlToPaper from 'vue-html-to-paper';
import Vuelidate from 'vuelidate'
import excel from 'vue-excel-export'
import VueCloneya from 'vue-cloneya'
import VueAutosuggest from "vue-autosuggest";

Vue.use(VueAutosuggest);
Vue.use(VueCloneya)
Vue.use(excel);
Vue.use(Vuelidate)
Vue.use(VCalendar);
const moment = require('moment')
 
Vue.use(require('vue-moment'), {
    moment
});

import { abilitiesPlugin } from '@casl/vue';
import ability from './services/ability';

const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css'
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
}

Vue.use(VueHtmlToPaper, options);

require("@/store/subscriber");

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL + "/api/";
Vue.config.performance = true
Vue.use(CoreuiVuePro)
Vue.prototype.$log = console.log.bind(console)

store.dispatch("auth/attempt", localStorage.getItem("token")).then(() => {

  Vue.use(abilitiesPlugin, ability);
  new Vue({
    el: '#app',
    data: {
      config(config) {
        var data = {
          server_URL: process.env.VUE_APP_BACKEND_URL,
          base_URL: process.env.VUE_APP_BASE_URL,
        };
        return data[config];
      },
      btn_load(status, elem, text) {
        var button = document.getElementById(elem);
        if (status) {
          button.innerHTML = text + ' <div role="status" aria-hidden="false" aria-label="Loading" class="spinner-border spinner-border-sm text-white"></div>';
          button.disabled = true; 
        } else {
          button.innerHTML = text;
          button.disabled = false; 
        }
      },
      momentParse(data) {
          return moment(data).fromNow();
      },
      momentFormat(data){
        return moment(data, "YYYY-MM-DD h:mm:ss").format('YYYY/MM/DD');
      },
      momentFormatDateTime(data){
        return moment(data, "YYYY-MM-DD h:mm:ss").format('YYYY/MM/DD hh:mm A');
      },
      momentFormatExpiration(data){
        return moment(data, "YYYY-MM-DD h:mm:ss").format('YYYY-MM-DD');
      }
    },
    
    router,
    store,
    icons,
    template: '<App/>',
    components: {
      App,
    }
  })
  
});
