import axios from "axios";
import ability from '../services/ability';

export default {
  namespaced: true,
  state: {
    token: null,
    user: null,
    permissions: JSON.parse(localStorage.getItem("permissions")) ? JSON.parse(localStorage.getItem("permissions")) : null,
  },
  getters: {
    authenticated(state) {
      return state.token && state.user;
    },

    user(state) {
      return state.user;
    },

    token(state) {
      return state.token;
    }
  },
  mutations: {
    SET_TOKEN(state, token) {
      
      state.token = token;
    },
    SET_USER(state, data) {
      state.user = data;
    },
    SET_PERMISSION(state, data) {
      state.permissions = data;
    },
  },
  actions: {
    async signIn({ commit, dispatch }, credetials) {
      let response = await axios.post("auth/login", credetials);
      console.log(response);

      if(response.status == 200){
        dispatch('loading/addLoading', {
          type: 'success',
          message: 'Successfully Login!'
        }, {root: true});

        dispatch('notification/addNotification', {
          type: 'success',
          message: 'Successfully Login!'
        }, {root: true});

        if (response.data.access_token) {
          commit("SET_TOKEN", response.data.access_token);

          dispatch('activity_log/addActivityLog', {
            activity: 'has logged in'
          }, {root: true});
        }
      }

      let permissions = [];
      let permission = [];
      response.data.permissions.forEach(item => {
        if(item.permission){
          permission.push({action: item.permission.action, subject: item.permission.permission_key})
          permissions.push({action: item.permission.action, page: item.permission.page, key:item.permission.permission_key})
        }
      });

      ability.update(permission);
      
      localStorage.setItem("permissions", JSON.stringify(permissions));

      commit('SET_PERMISSION', permissions);

      return dispatch("attempt", response.data.access_token);
    },

    async attempt({commit, state, dispatch }, token) {

      if (token) {
        commit("SET_TOKEN", token);
      }
      if (!state.token) {
        return;
      }

      try {
        let response = await axios.get("auth/me");
        commit("SET_USER", response.data);
        
        // dispatch('permission/fetchPermission', {}, {root: true});
      } catch (e) {
        commit("SET_TOKEN", null);
        commit("SET_USER", null);
      }
    },

    async refetchUser({commit, state, dispatch }, token) {

      if (token) {
        commit("SET_TOKEN", token);
      }
      if (!state.token) {
        return;
      }

      try {
        let response = await axios.get("auth/me");
        commit("SET_USER", response.data);
        dispatch('permission/fetchPermission', {}, {root: true});
      } catch (e) {
        commit("SET_TOKEN", null);
        commit("SET_USER", null);
      }
    },

    async updateuser({ commit }) {
      try {
        let response = await axios.get("auth/me");
        commit("SET_USER", response.data);
      } catch (e) {
        commit("SET_TOKEN", null);
        commit("SET_USER", null);
      }
    },

    async changepassword({state, dispatch}, data) {
      return new Promise((resolve, reject) => {
          axios.post("auth/change_password", {
            password: data.password,
            id: state.user.id
          }).then(response => {
              dispatch('activity_log/addActivityLog', {
                activity: 'has changed password'
              }, {root: true});

                resolve(response.data);
            }, error => {
                reject(error);
            });
      })
    },

    async forgotpassword({state}, data) {
      return new Promise((resolve, reject) => {
        axios.post("auth/request_password", data).then(response => {
              resolve(response.data);
          }, error => {
              reject(error);
          });
        })
    },

    async check_verification({state}, data) {
      return new Promise((resolve, reject) => {
        axios.post("auth/check_verification_code", data).then(response => {
              resolve(response.data);
          }, error => {
              reject(error);
          });
        })
    },

    async checkValidSignature({state}, data) {
      let response = await axios.post("auth/check_signature", data);
    },

    async changeForgotPassword({state}, data){
      return new Promise((resolve, reject) => {
      axios.post("auth/change_forgot_password", data).then(response => {
            resolve(response.data);
        }, error => {
            reject(error);
        });
      })
    },

    signOut({ commit, dispatch }) {
      dispatch('loading/addLoading', {
        type: 'success',
        message: 'Successfully Logout!'
      }, {root: true});

      dispatch('activity_log/addActivityLog', {
        activity: 'has logged out'
      }, {root: true});

      return axios.post("auth/logout").then(() => {
        commit("SET_TOKEN", null);
        commit("SET_USER", null);
      });
    }
  }
};
