import axios from "axios";

export default {
  namespaced: true,
  state: {
    owners: null,
  },
  getters: {
    owners(state) {
      return state.owners;
    },
  },
  mutations: {
    SET_OWNERS(state, data) {
        state.owners = data;
      },
  
      NEW_OWNERS(state, data) {
        let items = state.owners.concat(data);
        state.owners = items;
      },
  
      UPDATE_OWNERS(state, data) {
        state.owners.forEach(v => {
          if(v.id == data.id){
            v = data;
          }
        });
      },
  
      REMOVE_OWNERS(state, id) {
        let owners = state.owners.filter(owner => owner.id != id);
        state.owners = owners;
      },
  },
  actions: {
    async fetchOwners({commit}) {
        const response = await axios.get('action/get_owners');

        commit('SET_OWNERS', response.data);
    },

    async addOwner({commit, dispatch}, data) {
      await axios.post("action/add_owner", {
        form: data
      }).then(response => {
        dispatch('notification/addNotification', {
          type: 'success',
          message: 'Successfully Added!'
        }, {root: true});

        commit('NEW_OWNERS', response.data);
      }, error => {
        console.log(error);
        dispatch('notification/addNotification', {
          type: 'danger',
          message: 'Ops! Something went wrong!'
        }, {root: true});
        
      });
    },

    async updateOwner({commit,dispatch},data) {
      await axios.post("action/update_owner", {
          form: data
      }).then(response => {
        dispatch('notification/addNotification', {
          type: 'success',
          message: 'Successfully Updated!'
        }, {root: true});

        commit('UPDATE_USERS', response.data);
      }, error => {
        console.log(error);
        dispatch('notification/addNotification', {
          type: 'danger',
          message: 'Ops! Something went wrong!'
        }, {root: true});
        
      });
        
    },

    async removeOwner({ commit, dispatch }, data) {
      await axios.post("action/delete_owner", {
          id: data
      }).then(response => {
        dispatch('notification/addNotification', {
          type: 'success',
          message: 'Successfully Deleted!'
        }, {root: true});

        commit('REMOVE_OWNERS', response.data);
      }, error => {
        console.log(error);
        dispatch('notification/addNotification', {
          type: 'danger',
          message: 'Ops! Something went wrong!'
        }, {root: true});
        
      });
    },

  }
};
