<template>
    <CModal
        title="SET ROLE ELEMENT"
        :show.sync="$store.state.permission.permission_modal"
        :no-close-on-backdrop="false"
        :centered="true"
        size="sm"
        color="dark"
        >
        <CInput
            v-model='form.name'
            label="ELEMENT ROLE NAME"
            autocomplete="given-name"
        />
        <template #footer>
        <CButton color="success" @click="submitPermission" class="branding-btn">SUBMIT</CButton>
        <CButton @click="$store.dispatch('permission/permissionModalClose')" color="danger">CANCEL</CButton>
        </template>
    </CModal>
</template>

<script>
export default {
    data(){
        return {
            openModal: false,
            form: this.getEmptyForm()
        }
    },
    computed: {
        permissions(){
            return this.$store.state.permission.permission_modal_data;
        }
    },
    watch: {
        permissions(data){
            this.form = this.getEmptyForm();

            if(Object.keys(data.data).length !== 0){
                this.form.id = data.data.id;
                this.form.name = data.data.title;
                this.form.permission_key = data.element_key;
                this.form.page_name = data.page_name;
                this.form.action = data.action;
            }else{
                this.form.permission_key = data.element_key;
                this.form.page_name = data.page_name;
                this.form.action = data.action;
            }
            
        }
    },
    methods: {
        getEmptyForm(){
            return {
                id: '',
                name: '',
                permission_key: '',
                page_name: '',
                action: ''
            }
        },
        submitPermission(){
            console.log(this.form);
            this.$store.dispatch('permission/submitElementPermission', this.form).then(() => {
                this.$store.dispatch('permission/permissionModalClose');
                this.form = this.getEmptyForm();
            });

        }
    }
}
</script>

<style>

</style>