import Vue from 'vue';
import store from "@/store";
import Router from 'vue-router';
import axios from 'axios';
import ability from '../services/ability';

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')
const Sales2 = () => import('@/views/Sales_2/View')
const Sales_Report = () => import('@/views/Sales_Report/View')
const Expenses_Report = () => import('@/views/Expenses_Report/View')
const Login = () => import('@/views/authentication/Login')
const Users = () => import('@/views/Users/View.vue')
const BranchList = () => import('@/views/Branches/List/View')
const BranchFranchise = () => import('@/views/Branches/Franchise/View')
const Customers = () => import('@/views/History/View')
const Items = () => import('@/views/Inventory/Items/View')
const Personnel = () => import('@/views/Employee/Personnel/View')
const Incentives = () => import('@/views/Employee/Incentives/View')
const Attendance = () => import('@/views/Attendance/View')
const Customize = () => import('@/views/Customize/View')
const Members = () => import('@/views/Members/View')
const Profile = () => import('@/views/Profile/View')
const Forgot = () => import('@/views/authentication/Forgot')
const Reset = () => import('@/views/authentication/Reset')
const Expired = () => import('@/views/authentication/Expired')
const Unauthorized = () => import('@/views/authentication/404')
const VerificationCode = () => import('@/views/authentication/VerificationCode')
const Expenses = () => import('@/views/Expenses/View')
const Shift = () => import('@/views/Shift/View')
const Salary = () => import('@/views/Salary/View')
const MemberProfile = () => import('@/views/Members/MemberProfile')
const PersonnelProfile = () => import('@/views/Employee/Personnel/PersonnelProfile')
const OperationPurchaseOrder = () => import('@/views/OperationInventory/PurchaseOrder/View')
const OperationDeliveryReceipt = () => import('@/views/OperationInventory/DeliveryReceipt/View')
const OperationInventoryReport = () => import('@/views/OperationInventory/InventoryReport/View')
const PurchaseOrder = () => import('@/views/Inventory/PurchaseOrder/View')
const DeliveryReceipt = () => import('@/views/Inventory/DeliveryReceipt/View')
const InventoryReport = () => import('@/views/Inventory/InventoryReport/View')
const ActivityLog = () => import('@/views/ActivityLog/View')
const Stockout = () => import('@/views/OperationInventory/Stockout/View')

Vue.use(Router)

const router = new Router({
  mode: 'history', 
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/dashboard',
      redirect: '/dashboard',
      name: 'Home',
      component: TheContainer,
      children: [
        {
            path: '',
            name: 'Dashboard',
            component: Dashboard,
            meta: {
              requiresAuth: true,
              key: 'FL25Z1h'
            },
        }
      ]
    },
    {
      path: '/transaction',
      redirect: '/transaction/sales',
      name: 'Transaction',
      component: TheContainer,
      children: [
        // {
        //     path: 'sales',
        //     name: 'Sales',
        //     component: Sales,
        //     beforeEnter: (to, from, next) => {
        //       if (!store.getters["auth/authenticated"]) {
        //         next({
        //           name: "Login"
        //         });
        //       }
        //       next();
        //     },
        // },
        {
            path: 'sales',
            name: 'Sales',
            component: Sales2,
            meta: {
              requiresAuth: true,
              key: 'Zj4cS6M'
            },
        },
        {
            path: 'customers/history',
            name: 'History',
            component: Customers,
            meta: {
              requiresAuth: true,
              key: 'WctLZ3I'
            },
        },
        {
            path: 'customers/members',
            name: 'Members',
            component: Members,
            meta: {
              requiresAuth: true,
              key: 'gw52mWf'
            },
        },
        {
          path: 'customers/members_profile/:id',
          name: 'MemberProfile',
          component: MemberProfile,
          meta: {
            requiresAuth: true,
            key: ''
          },
        },
        {
            path: 'operation-inventory/delivery-receipt',
            name: 'FranchiseDeliveryReceipt',
            component: OperationDeliveryReceipt,
            meta: {
              requiresAuth: true,
              key: 'owd8G3R'
            },
        },
        {
            path: 'operation-inventory/stockout',
            name: 'Stockout',
            component: Stockout,
            meta: {
              requiresAuth: true,
              key: 'owd8G3R'
            },
        },
        {
            path: 'operation-inventory/purchase-order',
            name: 'FranchisePurchaseOrder',
            component: OperationPurchaseOrder,
            meta: {
              requiresAuth: true,
              key: '6BG5tBT'
            },
        },
        {
            path: 'operation-inventory/inventory-report',
            name: 'FranchiseInventoryReport',
            component: OperationInventoryReport,
            meta: {
              requiresAuth: true,
              key: 'bUqgw6x'
            },
        },
        {
            path: 'inventory/purchase-order',
            name: 'PurchaseOrder',
            component: PurchaseOrder,
            meta: {
              requiresAuth: true,
              key: '6BG5tBT'
            },
        },
        {
            path: 'inventory/delivery-receipt',
            name: 'DeliveryReceipt',
            component: DeliveryReceipt,
            meta: {
              requiresAuth: true,
              key: '6BG5tBT'
            },
        },
        {
            path: 'inventory/inventory-report',
            name: 'InventoryReport',
            component: InventoryReport,
            meta: {
              requiresAuth: true,
              key: '6BG5tBT'
            },
        },
        {
            path: 'inventory/items',
            name: 'InventoryItems',
            component: Items,
            meta: {
              requiresAuth: true,
              key: 'bUqgw6x'
            },
        },
        {
          path: 'employee/personnel',
          name: 'Personnel',
          component: Personnel,
          meta: {
            requiresAuth: true,
            key: 'nSpCV4H'
          },
        },
        {
          path: 'employee/personel/:id',
          name: 'PersonnelProfile',
          component: PersonnelProfile,
          meta: {
            requiresAuth: true
          },
        },
        {
          path: 'employee/shift',
          name: 'Shift',
          component: Shift,
          meta: {
            requiresAuth: true
          },
        },
        {
          path: 'employee/incentives',
          name: 'Incentives',
          component: Incentives,
          meta: {
            requiresAuth: true,
            key: 'DU7vihv'
          },
        },
        {
          path: 'employee/attendance',
          name: 'Attendance',
          component: Attendance,
          meta: {
            requiresAuth: true
          },
        },
        {
          path: 'employee/salary',
          name: 'Salary',
          component: Salary,
          meta: {
            requiresAuth: true
          },
        },
      ]
    },
    {
      path: '/users',
      component: TheContainer,
      children: [
        {
            path: '',
            name: 'Users',
            component: Users,
            meta: {
              requiresAuth: true,
              key: 'IPngBzy'
            },
        }
      ]
    },
    {
      path: '/expenses',
      component: TheContainer,
      children: [
        {
            path: '',
            name: 'Expenses',
            component: Expenses,
            meta: {
              requiresAuth: true
            },
        }
      ]
    },
    {
      path: '/customize',
      component: TheContainer,
      children: [
        {
            path: '',
            name: 'Customize',
            component: Customize,
            meta: {
              requiresAuth: true,
              key: 'WammP4r'
            },
        }
      ]
    },
    {
      path: '/activity_logs',
      component: TheContainer,
      children: [
        {
            path: '',
            name: 'ActivityLogs',
            component: ActivityLog,
            meta: {
              requiresAuth: true,
              key: 'WammP4r'
            },
        }
      ]
    },
    {
      path: '/profile',
      component: TheContainer,
      children: [
        {
            path: '',
            name: 'Profile',
            component: Profile,
            meta: {
              requiresAuth: true
            },
        }
      ]
    },
    {
      path: '/branch',
      name: 'Branch',
      component: TheContainer,
      children: [
        {
            path: 'list',
            name: 'BranchList',
            component: BranchList,
            meta: {
              requiresAuth: true,
              key: 'gDVzjax'
            },
        },
        {
          path: 'franchise',
          name: 'BranchFranchise',
          component: BranchFranchise,
          meta: {
            requiresAuth: true,
            key: 'Akewq1u'
          },
        }
      ]
    },
    {
      path: '/report',
      name: 'Report',
      component: TheContainer,
      children: [
        {
            path: '/report/sales',
            name: 'Sales Report',
            component: Sales_Report,
            meta: {
              requiresAuth: true, 
              key: 'DKRW2gO'
            },
        },
        {
          path: '/report/expenses',
            name: 'Expenses Report',
            component: Expenses_Report,
            meta: {
              requiresAuth: true
            },
        }
      ]
    },
    {
      path: '/',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
            path: '',
            name: 'Login',
            component: Login,
            meta: {
              requiresAuth: false
            },
        },
        {
            path: '/forgot_password',
            name: 'Forgot',
            component: {
              render (c) { return c('router-view') }
            },
            children: [
              {
                  path: '/',
                  name: 'Forgot_Password',
                  component: Forgot,
              },
              {
                  path: 'verification_code/:email',
                  name: 'Verification',
                  component: VerificationCode,
                  beforeEnter: (to, from, next) => {
                    console.log(to.params.email);
                    axios.post('auth/check_email', {
                      email: to.params.email
                    }).then(function(response){
                      if(response.data == 200){
                        next();
                      }else{
                        next({
                          name: "Unauthorized"
                        })
                      }
                    });
                  },
              }
            ]
        },
        {
          path: '/reset',
          name: 'Reset',
          component: Reset
        },
        {
          path: '/expired',
          name: 'Expired',
          component: Expired
        },
        {
          path: '/404',
          name: 'Unauthorized',
          component: Unauthorized
        }
      ]
    },
    
  ]
})

router.beforeEach((to, from, next) => {
  if(to.meta.requiresAuth){
    if (!store.getters["auth/authenticated"]) {
      next({
        name: "Login"
      });
    }

    if(to.name == 'Profile'){
      next();
    }else{
      if(ability.can('read', to.meta.key)){
        next();
      }else{
        next({
          name: "Unauthorized"
        });
      }
    }
  }else{
    next();
  }
});

export default router;