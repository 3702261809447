import axios from "axios";

export default {
  namespaced: true,
  state: {
    company: [],
  },
  mutations: {
    SET_COMPANY(state, data) {
        state.company = data;
    },
    
    NEW_COMPANY(state, data) {
      let items = state.company.concat(data);
      state.company = items;
    },

    UPDATE_COMPANY(state, data){
        const index = state.company.findIndex(item => item.id === data.id);
        if(index !== -1){
          state.company.splice(index, 1, data);
        }
    },

    REMOVE_COMPANY(state, id) {
        state.company = state.company.filter(item => item.id !== id)
    },
  },
  actions: {
    async fetchCompany({commit}) {
        const response = await axios.get(`action/companies`);

        commit('SET_COMPANY', response.data);
    },

    async addCompany({commit, dispatch}, payload) {
        try{
            const response = await axios.post('action/companies', payload);
            commit('NEW_COMPANY', response.data);
            dispatch('notification/addNotification', {
            type: 'success',
            message: 'Successfully Added!'
            }, {root: true});
        }catch{
            dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Ops! Something went wrong!'
            }, {root: true});
        }
    },

    async deleteCompany({commit, dispatch}, id) {
        try{
            const response = await axios.delete(`action/companies/${id}`);
            commit('REMOVE_COMPANY', parseInt(response.data));
            dispatch('notification/addNotification', {
            type: 'success',
            message: 'Successfully Deleted!'
            }, {root: true});
        }catch{
            dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Ops! Something went wrong!'
            }, {root: true});
        }
    },

    async updateCompany({commit, dispatch}, payload) {
        try{
            const response = await axios.put(`action/companies/${payload.id}`, payload);
            commit('UPDATE_COMPANY', response.data);
            dispatch('notification/addNotification', {
            type: 'success',
            message: 'Successfully Added!'
            }, {root: true});
        }catch{
            dispatch('notification/addNotification', {
            type: 'danger',
            message: 'Ops! Something went wrong!'
            }, {root: true});
        }
    },
  }
};
