export default {
  namespaced: true,
  state: {
    recent_pages: [],
    favorite: []
  },
  getters: {
    recent_pages(state) {
      return state.recent_pages;
    },
    favorites(state) {
      return state.favorite;
    },
  },
  mutations: {
    SET_PAGES(state, data) {
        state.recent_pages = data;
      },
    SET_FAVORITE(state, data) {
      state.favorite = data;
    },
  },
  actions: {
    async fetchPages({commit}) {
        commit('SET_PAGES', JSON.parse(localStorage.getItem("recent_pages")));
    },
    async fetchFavorite({commit}) {
        commit('SET_FAVORITE', JSON.parse(localStorage.getItem("favorite")));
    },
    async savePage({dispatch}, data) {
        const get_recent = JSON.parse(localStorage.getItem("recent_pages"));
        let data_array = [];
        if(get_recent == null){
            data_array = [{
            name: data.name,
            path: data.path,
            index: data.index
            }];
            localStorage.setItem("recent_pages", JSON.stringify(data_array));
            dispatch('fetchPages');
        }else{
            get_recent.push({
                name: data.name,
                path: data.path,
                index: data.index
            });

            localStorage.setItem("recent_pages", JSON.stringify(get_recent));
            dispatch('fetchPages');
        }
    },

    async saveFavorite({dispatch}, data) {
        const favorite = JSON.parse(localStorage.getItem("favorite"));
        let data_array = [];
        if(favorite == null){
            data_array = [{
            name: data.name,
            path: data.path,
            id: data.id
            }];
            localStorage.setItem("favorite", JSON.stringify(data_array));
            dispatch('fetchFavorite');
        }else{
          favorite.push({
                name: data.name,
                path: data.path,
                id: data.id
            });

            localStorage.setItem("favorite", JSON.stringify(favorite));
            dispatch('fetchFavorite');
        }
    },
    async updateFavorite({dispatch}, data) {
        localStorage.removeItem("favorite");
        localStorage.setItem("favorite", JSON.stringify(data));
        dispatch('fetchFavorite');
    },
  }
};
